import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useContext } from "react";
import DataContext from "../../context/data/dataContext";
import navigationContext from "../../context/navigation/navigationContext";

const initialValues = {
  carrier: "",
  lastName: "",
  firstName: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  phone: "",
  email: "",
  installRequestedBy: "",
  activeStatus: "New",
  account_num: "",
  billDate: "",
};

const CreateForm = () => {
  const { createConnection } = useContext(DataContext);
  const { loadModule } = useContext(navigationContext);
  const [newConnection, setNewConnection] = useState(initialValues);

  const handleChange = (e) => {
    setNewConnection({ ...newConnection, [e.target.name]: e.target.value });
  };

  const cancel = () => {
    setNewConnection(initialValues);
    loadModule({ module: "Open", content: "Open List" });
  };

  const submit = () => {
    createConnection(newConnection);
    setNewConnection(initialValues);
    loadModule({ module: "Open", content: "Open List" });
  };

  return (
    <Container>
      <Paper elevation={8} sx={{ py: 4, px: 2, mt: 2 }}>
        <Typography variant="h6" color="primary">
          CREATE CONNECTION
        </Typography>
        <Grid container sx={{ mt: 1 }}>
          <Grid item xs={12} xl={6}>
            <Card>
              <CardContent>
                <Grid container>
                  <Grid item xs={12} xl={6}>
                    <TextField
                      sx={{ width: "98%" }}
                      variant="outlined"
                      label="First Name"
                      name="firstName"
                      value={newConnection.firstName}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} xl={6}>
                    <TextField
                      sx={{ width: "98%" }}
                      variant="outlined"
                      name="lastName"
                      label="Last Name"
                      value={newConnection.lastName}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      sx={{ width: "99%", mt: 1 }}
                      variant="outlined"
                      name="address"
                      label="Address"
                      value={newConnection.address}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      sx={{ width: "99%", mt: 1 }}
                      variant="outlined"
                      name="city"
                      label="City"
                      value={newConnection.city}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      sx={{ width: "99%", mt: 1 }}
                      variant="outlined"
                      name="state"
                      label="State"
                      value={newConnection.state}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      sx={{ width: "99%", mt: 1 }}
                      variant="outlined"
                      name="zip"
                      label="Zip"
                      value={newConnection.zip}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} xl={6}>
            <Card sx={{ ml: { xl: 1 } }}>
              <CardContent>
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      sx={{ width: "99%" }}
                      variant="outlined"
                      label="Phone"
                      name="phone"
                      value={newConnection.phone}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      sx={{ width: "99%", mt: 1 }}
                      variant="outlined"
                      name="email"
                      label="Email"
                      value={newConnection.email}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      sx={{ width: "99%", mt: 1 }}
                      variant="outlined"
                      name="carrier"
                      label="carrier"
                      value={newConnection.carrier}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 2 }}>
          <Grid item xl={6}>
            <Button variant="contained" color="error" onClick={cancel}>
              Cancel
            </Button>
          </Grid>
          <Grid item xl={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={submit}
              sx={{ float: "right" }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default CreateForm;
