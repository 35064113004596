import React, { useState } from 'react';
import UserAuthentication from './UserAuthentication';
import PinAuthentication from './PinAuthentication';

const Login = () => {
  const [authenticationStage, setAuthenticationState] = useState('user');

  switch (authenticationStage) {
    default:
    case 'user':
      return (
        <UserAuthentication setAuthenticationState={setAuthenticationState} />
      );
    case 'pin':
      return (
        <PinAuthentication setAuthenticationState={setAuthenticationState} />
      );
  }
};

export default Login;
