const SpectrumAcknowledgeEmailBody = (data) => {
  const text = `
        ${data.firstName},<br>
      
        <p>We have received a request to order internet service to be used at your home.  We are working with Spectrum to provide this service.  We will be in touch with you as it pertains to installation of service at your location.  </p>
        
        Glenn A. Taggart</n>
        AB&amp;T Telecom</n>
        240-654-1884 Office</n>
        240-337-8367 Fax</n>
        gtaggart@abttelecom.com</n>
        http://www.abttelecom.com</n>
        
        <p>Galileo - Hosted Voice &amp; UC Service http://www.galileo-uc.com</p>
          `;

  return text;
};

export default SpectrumAcknowledgeEmailBody;
