import React from "react";
import { IconButton } from "@mui/material";
import FileSaver from "file-saver";
import * as XLSX from "xlsx/xlsx.mjs";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

const ExportPaymentsExcel = (csvData, fileName) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const Heading = [
    {
      _id: "Record ID",
      submitDate: "Date Submitted",
      paymentAmount: "Amount",
      invoiceNumber: "Invoice #",
      employee: "Employee",
      submittedBy: "Submitted By",
      memo: "Memo",
    },
  ];

  const wscols = [
    { wch: 5 },
    { wch: 20 },
    { wch: 10 },
    { wch: 15 },
    { wch: 25 },
    { wch: 25 },
    { wch: 40 },
  ];

  const ws = XLSX.utils.json_to_sheet(Heading, {
    skipHeader: true,
    origin: 0, //ok
  });
  ws["!cols"] = wscols;
  XLSX.utils.sheet_add_json(ws, csvData, {
    skipHeader: true,
    origin: -1, //ok
  });
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export default ExportPaymentsExcel;
