import React, { Fragment, useContext } from "react";
import NavigationContext from "../../context/navigation/navigationContext";
import AuthContext from "../../context/auth/authContext";
import Menu from "../../components/nav/Menu";
import CreateButton from "../../components/button/Create";
import OrderButton from "../../components/button/SendOrder";
import AllConnections from "../allConnections/AllConnections";
import OpenConnections from "../openConnections/OpenConnections";
import ActiveConnections from "../activeConnections/ActiveConnections";
import PendingConnections from "../pendingConnections/PendingConnections";
import ArchivedConnections from "../archivedConnections/ArchivedConnections";
import DisconnectionConnections from "../disconnectConnections/DisconnectConnections";
import ResearchConnections from "../researchConnections/ResearchConnections";
import Payments from "../payments/Payments";
import Errors from "../errors/Errors";
import Users from "../users/Users";
import WeeklyReport from "../weekly/WeeklyReport";
import LoginList from "../loginReport/LoginList";

const Home = () => {
  const { module } = useContext(NavigationContext);
  const { user } = useContext(AuthContext);
  switch (module) {
    default:
    case "menu":
      return <Menu />;
    case "All":
      return <AllConnections />;
    case "Active":
      return <ActiveConnections />;
    case "Pending":
      return <PendingConnections />;
    case "Archive":
      return <ArchivedConnections />;
    case "Disconnect":
      return <DisconnectionConnections />;
    case "Research":
      return <ResearchConnections />;
    case "Users":
      return <Users />;
    case "Errors":
      return <Errors />;
    case "Logins":
      return <LoginList />;
    case "Weekly":
      return <WeeklyReport />;
    case "Payments":
      return <Payments />;
    case "Open":
      return (
        <Fragment>
          {user !== null && user !== undefined && user.role === "admin" ? (
            <>
              <OrderButton />
              <CreateButton />
            </>
          ) : null}
          <OpenConnections />
        </Fragment>
      );
  }
};

export default Home;
