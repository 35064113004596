import React, { useState, useContext, useEffect } from "react";
import { Navigate } from "react-router";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import NewAlert from "../../components/alert/NewAlert";
import AlertContext from "../../context/alert/alertContext";
import AuthContext from "../../context/auth/authContext";

const PinAuthentication = ({ setAuthenticationState }) => {
  const { isAuthenticated, loadUser, authPin, error, clearErrors } =
    useContext(AuthContext);
  const { setAlert } = useContext(AlertContext);

  const [pin, setPin] = useState({
    tempPin: null,
  });

  const onChange = (e) => setPin({ ...pin, tempPin: e.target.value });

  useEffect(() => {
    if (isAuthenticated) {
      loadUser();
      setAuthenticationState("user");
    }
    if (error === "PIN EXPIRED") {
      setAlert(true, "Pin Expired - Login Again", "error");
      setAuthenticationState("user");
      clearErrors();
    }
    if (error === "INVALID PIN") {
      setAlert(true, "Invalid Pin - Try Again", "error");
      clearErrors();
    }

    //eslint-disable-next-line
  }, [isAuthenticated, error]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (pin === "") {
      setAlert(true, "Please Enter Temporary Pin from Email", "error");
    } else if (pin.tempPin.length === 6) {
      authPin(pin);
    } else {
      setAlert(
        true,
        "Invalid Pin - Please Re-enter pin with no spaces",
        "error"
      );
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 9,
          marginBottom: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#FFF",
          borderRadius: "15px",
          p: 4,
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "#3f3b3b" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h6">ENTER TEMPORARY ACCESS PIN</Typography>
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="pin"
            name="pin"
            focused
            onChange={onChange}
          />

          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 2, float: "right" }}
            onClick={handleSubmit}
          >
            SUBMIT
          </Button>
        </Box>
        <NewAlert />
      </Box>
      {isAuthenticated && <Navigate to="/" />}
    </Container>
  );
};

export default PinAuthentication;
