import React, { useContext } from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigationContext from '../../context/navigation/navigationContext';

export default function BreadcrumbsBar() {
  const { module, content, loadModule } = useContext(NavigationContext);
  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 1, ml: 1 }}>
        <Typography
          color="text.primary"
          sx={{ cursor: 'pointer' }}
          onClick={() => loadModule({ module: 'Menu', content: 'Main' })}
        >
          HOME
        </Typography>
        <Typography color="text.primary">{module.toUpperCase()}</Typography>
        <Typography color="text.primary">{content.toUpperCase()}</Typography>
      </Breadcrumbs>
    </div>
  );
}
