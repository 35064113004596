import React, { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Header from '../components/nav/Header';
import BreadcrumbsBar from '../components/nav/BreadCrumbsBar';
import AuthContext from '../context/auth/authContext';
import Home from '../pages/home/Home';
import SessionTimeout from '../utils/SessionTimeout';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, loadUser, loading } = useContext(AuthContext);

  useEffect(() => {
    if (localStorage.auth_token) {
      loadUser(localStorage.auth_token);
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {!isAuthenticated && loading ? (
        <Navigate to="/login" />
      ) : (
        <>
          <Header />
          <BreadcrumbsBar />
          <Home />
          <SessionTimeout />
        </>
      )}
    </div>
  );
};

export default PrivateRoute;
