import React, { useState } from "react";
import PropTypes from "prop-types";
import ConnectionForm from "../../components/forms/ConnectionForm";
import ConnectionPayments from "../../components/forms/ConnectionPayments";
import ConnectionNotes from "../../components/forms/ConnectionNotes";
import { Box, Tab, Tabs } from "@mui/material";

const Connection = ({ focusedConnection, module }) => {
  const [tab, setTab] = useState(0);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Box>{children}</Box>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Tabs
          value={tab}
          onChange={handleChange}
          aria-label="Navigation Tabs"
          sx={{ width: "100%" }}
          centered
        >
          <Tab
            label="Profile"
            sx={{
              background: (theme) => theme.palette.primary.main,
              ...(tab === 0
                ? { "&.Mui-selected": { color: "orange" } }
                : { color: "white" }),
            }}
          />
          <Tab
            label="Notes"
            sx={{
              background: (theme) => theme.palette.primary.main,
              ...(tab === 1
                ? { "&.Mui-selected": { color: "orange" } }
                : { color: "white" }),
            }}
          />
          <Tab
            label="Payments"
            sx={{
              background: (theme) => theme.palette.primary.main,
              ...(tab === 2
                ? { "&.Mui-selected": { color: "orange" } }
                : { color: "white" }),
            }}
          />
          {/* <Tab
            label="Invoices"
            sx={{
              background: (theme) => theme.palette.primary.main,
              ...(tab === 3
                ? { "&.Mui-selected": { color: "orange" } }
                : { color: "white" }),
            }}
          />
          <Tab
            label="Contract"
            sx={{
              background: (theme) => theme.palette.primary.main,
              ...(tab === 4
                ? { "&.Mui-selected": { color: "orange" } }
                : { color: "white" }),
            }}
          /> */}
        </Tabs>
      </Box>
      <TabPanel value={tab} index={0}>
        <ConnectionForm focusedConnection={focusedConnection} module={module} />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <ConnectionNotes focusedConnection={focusedConnection} />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <ConnectionPayments focusedConnection={focusedConnection} />
      </TabPanel>
      {/* <TabPanel value={tab} index={3}>
        <ConnectionPayments />
      </TabPanel>
      <TabPanel value={tab} index={4}>
        <ConnectionPayments />
      </TabPanel> */}
    </Box>
  );
};

export default Connection;
