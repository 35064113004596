const ComcastEmailBody = (data) => {
  const text = `
  TCG,<br>

  Please Create order paperwork but send it via <mark>DOCUSIGN</mark> and CC Me<br>  
  <b>Primary Contact Name:</b> ${data.firstName} ${data.lastName} </n>
  <b>Primary Contact Phone:</b> ${data.phone} </n>
  <b>Secondary Contact phone:</b> 240-654-1884 </n>
  <b>Primary Contact Email:</b> optimaInternet@abttelecom.com</n>
  <b> Tech Contact name:</b> Glenn Taggart, 240-654-1884 gtaggart@abttelecom.com </n>
  <b>Billing Address:</b> OPTIMA Health Care, ATTN Kimberly Harriman, 8102 Tichenor Point Court Millersville, MD 21108 - khharrim@sentara.com 757.983.2490</n>
  <b>Full Name of Business/Account:</b> Optima Health Care</n>
  <b>Full Address of Business:</b> ${data.address} ${data.city}, ${data.state} ${data.zip}</n>
  <b>Services Being Requested:</b> 100mb on a 1 year term. Dynamic IP (NO WiFi is to be enabled)<br>
  
  <p><mark>This is a work at home employee circuit. Installed in the residence and billed to the corporate account.</mark></p>
  
  <p>Glenn A. Taggart</n>
  AB&amp;T Telecom</n>
  240-654-1884 Office</n>
  240-337-8367 Fax</n>
  gtaggart@abttelecom.com</n>
  http://www.abttelecom.com</n>
  
  <p>Galileo - Hosted Voice &amp; UC Service http://www.galileo-uc.com</p>
    `;

  return text;
};

export default ComcastEmailBody;
