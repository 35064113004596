import React, { Fragment, useContext } from "react";
import {
  Container,
  Paper,
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
} from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import PeopleIcon from "@mui/icons-material/People";
import LayersIcon from "@mui/icons-material/Layers";
import LogoutIcon from "@mui/icons-material/Logout";
import NavigationContext from "../../context/navigation/navigationContext";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import AuthContext from "../../context/auth/authContext";
import AssessmentIcon from "@mui/icons-material/Assessment";
import PersonIcon from "@mui/icons-material/Person";
import ArchiveIcon from "@mui/icons-material/Archive";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import FmdBadIcon from "@mui/icons-material/FmdBad";
import PaidIcon from "@mui/icons-material/Paid";

const Menu = () => {
  const { loadModule } = useContext(NavigationContext);
  const { logoutUser, user } = useContext(AuthContext);

  const logout = () => {
    logoutUser();
    loadModule({ module: "menu", content: "main" });
  };

  return (
    <Container
      sx={{
        mt: { xs: "20%", lg: "5%" },
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Paper elevation={8} sx={{ py: { xs: 3, lg: 6 }, px: { xs: 3, lg: 20 } }}>
        <Grid container>
          <Grid item xs={6} sx={{ px: 1 }}>
            <Card
              elevation={4}
              onClick={() =>
                loadModule({ module: "Active", content: "Active List" })
              }
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <DashboardIcon sx={{ color: "#feb062" }} fontSize="large" />
                <Typography variant="subtitle1" sx={{ mt: 1 }}>
                  ACTIVE
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} sx={{ px: 1 }}>
            <Card
              elevation={4}
              onClick={() => loadModule({ module: "All", content: "All List" })}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <LayersIcon sx={{ color: "#feb062" }} fontSize="large" />
                <Typography variant="subtitle1" sx={{ mt: 1 }}>
                  All
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={6} sx={{ px: 1 }}>
            <Card
              elevation={4}
              onClick={() =>
                loadModule({ module: "Archive", content: "Archive List" })
              }
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <ArchiveIcon sx={{ color: "#feb062" }} fontSize="large" />
                <Typography variant="subtitle1" sx={{ mt: 1 }}>
                  ARCHIVED
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} sx={{ px: 1 }}>
            <Card
              elevation={4}
              onClick={() =>
                loadModule({ module: "Open", content: "Open List" })
              }
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <NoteAddIcon sx={{ color: "#feb062" }} fontSize="large" />
                <Typography variant="subtitle1" sx={{ mt: 1 }}>
                  OPEN
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={6} sx={{ px: 1 }}>
            <Card
              elevation={4}
              onClick={() =>
                loadModule({ module: "Pending", content: "Pending List" })
              }
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <AutorenewIcon sx={{ color: "#feb062" }} fontSize="large" />
                <Typography variant="subtitle1" sx={{ mt: 1 }}>
                  PENDING
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={6} sx={{ px: 1 }}>
            <Card
              elevation={4}
              onClick={() =>
                loadModule({
                  module: "Disconnect",
                  content: "Disconnection List",
                })
              }
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <CancelPresentationIcon
                  sx={{ color: "#feb062" }}
                  fontSize="large"
                />
                <Typography variant="subtitle1" sx={{ mt: 1 }}>
                  DISCONNECT
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={6} sx={{ px: 1 }}>
            <Card
              elevation={4}
              onClick={() =>
                loadModule({ module: "Research", content: "Research List" })
              }
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <FindInPageIcon sx={{ color: "#feb062" }} fontSize="large" />
                <Typography variant="subtitle1" sx={{ mt: 1 }}>
                  RESEARCH
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} sx={{ px: 1 }}>
            <Card
              elevation={4}
              onClick={() =>
                loadModule({ module: "Payments", content: "Payments List" })
              }
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <PaidIcon sx={{ color: "#feb062" }} fontSize="large" />
                <Typography variant="subtitle1" sx={{ mt: 1 }}>
                  PAYMENTS LIST
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={6} sx={{ px: 1 }}>
            <Card elevation={4} onClick={logout}>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <LogoutIcon sx={{ color: "#feb062" }} fontSize="large" />
                <Typography variant="subtitle1" sx={{ mt: 1 }}>
                  LOGOUT
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/****************************************  ADMIN MENU ITEMS  ******************************************** */}
        {user !== null && user.role === "admin" ? (
          <Fragment>
            <Divider
              sx={{ mt: 2, borderWidth: "3px", borderColor: "#feb062" }}
            />
            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={6} sx={{ px: 1 }}>
                <Card
                  elevation={4}
                  sx={{ background: "#fafaf6" }}
                  onClick={() =>
                    loadModule({ module: "Weekly", content: "Weekly Report" })
                  }
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <AssessmentIcon
                      sx={{ color: "#3f3b3b" }}
                      fontSize="large"
                    />
                    <Typography variant="subtitle1" sx={{ mt: 1 }}>
                      WEEKLY REPORT
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6} sx={{ px: 1 }}>
                <Card
                  elevation={4}
                  sx={{ background: "#fafaf6" }}
                  onClick={() =>
                    loadModule({ module: "Users", content: "Users List" })
                  }
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <PersonIcon sx={{ color: "#3f3b3b" }} fontSize="large" />
                    <Typography variant="subtitle1" sx={{ mt: 1 }}>
                      USERS
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={6} sx={{ px: 1 }}>
                <Card
                  elevation={4}
                  sx={{ background: "#fafaf6" }}
                  onClick={() =>
                    loadModule({ module: "Errors", content: "Errors List" })
                  }
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <FmdBadIcon sx={{ color: "#3f3b3b" }} fontSize="large" />
                    <Typography variant="subtitle1" sx={{ mt: 1 }}>
                      ERRORS
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6} sx={{ px: 1 }}>
                <Card
                  elevation={4}
                  sx={{ background: "#fafaf6" }}
                  onClick={() =>
                    loadModule({ module: "Logins", content: "Logins List" })
                  }
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <PeopleIcon sx={{ color: "#3f3b3b" }} fontSize="large" />
                    <Typography variant="subtitle1" sx={{ mt: 1 }}>
                      LOGIN LOGS
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Fragment>
        ) : null}
      </Paper>
    </Container>
  );
};

export default Menu;
