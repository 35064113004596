import React, { useState, useEffect } from "react";
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TablePagination,
  Grid,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ExportPaymentsExcel from "./ExportPaymentsExcel";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import axios from "axios";

const PaymentsList = () => {
  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
  });
  const [connections, setConnections] = useState(null);
  const [users, setUsers] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [payments, setPayments] = useState(null);
  const [displayPayments, setDisplayPayments] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("submitDate");

  const fetchPayments = async () => {
    try {
      const res = await axios.get("/api/payments/all");
      setDisplayPayments(res.data);
      setPayments(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchConnections = async () => {
    try {
      const res = await axios.get("/api/connections/");
      setConnections(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchUsers = async () => {
    try {
      const res = await axios.get("/api/auth/all");
      setUsers(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const filterDateRange = () => {
    if (dateRange.startDate === null && dateRange.endDate === null) {
      setDisplayPayments(payments);
    } else {
      const sd = new Date(dateRange.startDate);
      const syy = sd.getFullYear();
      const sdd = ("0" + sd.getDate()).slice(-2);
      const smm = ("0" + sd.getMonth()).slice(-2);
      const ed = new Date(dateRange.endDate);
      const eyy = ed.getFullYear();
      const edd = ("0" + ed.getDate()).slice(-2);
      const emm = ("0" + ed.getMonth()).slice(-2);

      const startDate = new Date(syy, smm, sdd, 0, 0, 0);
      const endDate = new Date(eyy, emm, edd, 23, 59, 59);

      const convertDate = (date) => {
        const d = new Date(date);
        const yy = d.getFullYear();
        const dd = ("0" + (d.getDate() + 1)).slice(-2);
        const mm = ("0" + d.getMonth()).slice(-2);

        const newDate = new Date(yy, mm, dd, 5, 0, 0);

        return newDate;
      };
      const newList = payments.filter(
        (payment) =>
          convertDate(payment.submitDate) > startDate &&
          convertDate(payment.submitDate) < endDate
      );

      setDisplayPayments(newList);
    }
  };

  useEffect(() => {
    fetchPayments();
    fetchUsers();
    fetchConnections();

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (dateRange.startDate !== "" && dateRange.endDate !== "") {
      if (new Date(dateRange.startDate) <= new Date(dateRange.endDate)) {
        filterDateRange();
      }
    }
    if (dateRange.startDate === null && dateRange.endDate === null) {
      filterDateRange();
    }

    //eslint-disable-next-line
  }, [dateRange]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const convertToDefEventPara = (name, value) => ({
    target: {
      name,
      value,
    },
  });

  const handleChange = (e) => {
    setDateRange({
      ...dateRange,
      [e.target.name]: e.target.value,
    });
  };

  const userLookUp = (id) => {
    if (users === null) return;
    const employee = users.filter((user) => user._id === id);
    const fullName = `${employee[0].firstName} ${employee[0].lastName} `;

    return fullName;
  };

  const employeeLookUp = (id) => {
    if (connections === null) return;
    const employee = connections.filter((connection) => connection._id === id);
    const fullName = `${employee[0].firstName} ${employee[0].lastName} `;

    return fullName;
  };

  const exportToCSV = () => {
    var formatedData = [];
    const date = Date.now();
    const fileName = `Payments Exports - ${date}`;
    displayPayments.map((payment) => {
      const employeeName = employeeLookUp(payment.connectionID);
      const submittedBy = userLookUp(payment.submittedBy);
      const recordData = {
        _id: payment._id,
        submitDate: payment.submitDate,
        paymentAmount: payment.paymentAmount,
        invoiceNumber: payment.invoiceNumber,
        employee: employeeName,
        submittedBy: submittedBy,
        memo: payment.memo,
      };
      formatedData.push(recordData);

      return null;
    });

    ExportPaymentsExcel(formatedData, fileName);
  };

  return (
    <Paper sx={{ px: 4, py: 2 }}>
      <Grid container>
        <Grid item xs={12} sm={8} md={8} lg={7} xl={5}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Start Date"
              name="startDate"
              value={dateRange.startDate}
              onChange={(date) =>
                handleChange(convertToDefEventPara("startDate", date))
              }
              renderInput={(params) => (
                <TextField {...params} sx={{ minWidth: "45%", mt: 1 }} />
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="End Date"
              name="endDate"
              value={dateRange.endDate}
              onChange={(date) =>
                handleChange(convertToDefEventPara("endDate", date))
              }
              renderInput={(params) => (
                <TextField {...params} sx={{ minWidth: "45%", mt: 1, ml: 1 }} />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={1} sx={{ pt: 3 }}>
          <Typography
            variant="body2"
            color="error"
            onClick={() => setDateRange({ startDate: null, endDate: null })}
            sx={{ cursor: "pointer", fontWeight: "bold" }}
          >
            {" "}
            Reset
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={6}>
        {/* ********************* EXPORT FEATURE ***************************** */}
        {/* <ExportPaymentsExcel csvData={csvData} fileName={fileName} /> */}
        <IconButton
          sx={{ float: "right", color: "#3f3b3b" }}
          onClick={exportToCSV}
        >
          <DownloadForOfflineIcon />
        </IconButton>
      </Grid>
      <TableContainer sx={{ maxHeight: 700, mt: 2 }}>
        <Table size="small" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  color: "#feb062",
                  backgroundColor: "#3f3b3b",
                  cursor: "pointer",
                }}
                onClick={() => handleRequestSort("submitDate")}
              >
                Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "center",
                  color: "#feb062",
                  backgroundColor: "#3f3b3b",
                  cursor: "pointer",
                }}
                onClick={() => handleRequestSort("paymentAmount")}
              >
                Amount
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "center",
                  color: "#feb062",
                  backgroundColor: "#3f3b3b",
                  cursor: "pointer",
                }}
                onClick={() => handleRequestSort("invoiceNumber")}
              >
                Invoice #
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "center",
                  color: "#feb062",
                  backgroundColor: "#3f3b3b",
                  cursor: "pointer",
                }}
                onClick={() => handleRequestSort("connectionID")}
              >
                Employee
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "center",
                  color: "#feb062",
                  backgroundColor: "#3f3b3b",
                  cursor: "pointer",
                }}
                onClick={() => handleRequestSort("submittedBy")}
              >
                Submitted By
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "center",
                  color: "#feb062",
                  backgroundColor: "#3f3b3b",
                  cursor: "pointer",
                }}
                onClick={() => handleRequestSort("carrier")}
              >
                Carrier
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayPayments !== null && displayPayments !== undefined
              ? displayPayments
                  .sort(getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((payment) => (
                    <TableRow hover key={payment._id}>
                      <TableCell
                        sx={{
                          textAlign: "left",
                        }}
                      >
                        {payment.submitDate}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        ${payment.paymentAmount}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        {payment.invoiceNumber}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        {employeeLookUp(payment.connectionID)}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        {userLookUp(payment.submittedBy)}
                      </TableCell>

                      <TableCell
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        {payment.carrier}
                      </TableCell>
                    </TableRow>
                  ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      {displayPayments !== null &&
      displayPayments !== undefined &&
      displayPayments.length > 12 ? (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={displayPayments.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            color: "#feb062",
            backgroundColor: "#3f3b3b",
          }}
        />
      ) : null}
    </Paper>
  );
};

export default PaymentsList;
