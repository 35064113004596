import React, { useEffect, useState } from "react";
import {
  Container,
  Paper,
  Table,
  TableHead,
  TableRow,
  Typography,
  TableCell,
  TableBody,
  Button,
  TablePagination,
  TableContainer,
} from "@mui/material";
import axios from "axios";
import mmddyy from "../../utils/mmddyy";

const LoginList = () => {
  const [logins, setLogins] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchData = async () => {
    try {
      const res = await axios.get("/api/loginLogs");

      setLogins(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const deleteLogin = async (id) => {
    var updatedLoginsList = [];
    try {
      await axios.delete(`/api/loginLogs/${id}`);
      const filteredLogins = logins.filter((login) => login._id !== id);
      filteredLogins.map((login) => updatedLoginsList.push(login));
      setLogins(filteredLogins);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <Paper>
        <Typography
          variant="h4"
          color="primary"
          sx={{ mt: 3, textAlign: "center" }}
        >
          LOGIN LOG
        </Typography>
        <TableContainer sx={{ maxHeight: 700, mt: 2 }}>
          <Table size="large" stickeyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: "#e2e2e2", background: "#3f3b3b" }}>
                  ID
                </TableCell>
                <TableCell
                  sx={{
                    color: "#e2e2e2",
                    textAlign: "center",
                    background: "#3f3b3b",
                  }}
                >
                  USERNAME
                </TableCell>
                <TableCell
                  sx={{
                    color: "#e2e2e2",
                    textAlign: "center",
                    background: "#3f3b3b",
                  }}
                >
                  DATE
                </TableCell>
                <TableCell
                  sx={{
                    color: "#e2e2e2",
                    textAlign: "center",
                    background: "#3f3b3b",
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logins !== null && logins !== undefined
                ? logins
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((login) => (
                      <TableRow hover key={login._id}>
                        <TableCell>{login._id}</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {login.userName}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {mmddyy(login.createdAt)}
                        </TableCell>
                        <TableCell>
                          <Button
                            color="error"
                            variant="contained"
                            onClick={() => deleteLogin(login._id)}
                            sx={{ float: "right" }}
                          >
                            DELETE
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        {logins !== null && logins !== undefined && logins.length > 12 ? (
          <TablePagination
            rowsPerPageOptions={[12, 25, 50, 100]}
            component="div"
            count={logins.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              color: "#e2e2e2",
              backgroundColor: "#3f3b3b",
            }}
          />
        ) : null}
      </Paper>
    </Container>
  );
};

export default LoginList;
