import {
  GET_DATA,
  CLEAR_DATA,
  START_FETCHING,
  CREATE_DATA,
  EDIT_DATA,
  DELETE_DATA,
} from "../types";

const dataReducer = (state, action) => {
  switch (action.type) {
    case GET_DATA:
      return {
        ...state,
        connections: action.payload,
        fetching: false,
      };
    case CREATE_DATA:
      return {
        ...state,
        connections: [action.payload, ...state.connections],
        fetching: false,
      };
    case EDIT_DATA:
      return {
        ...state,
        connections: [
          action.payload,
          ...state.connections.filter(
            (connection) => connection._id !== action.payload._id
          ),
        ],
        fetching: false,
      };
    case DELETE_DATA:
      return {
        ...state,
        connections: [
          ...state.connections.filter(
            (connection) => connection._id !== action.payload
          ),
        ],
        fetching: false,
      };
    case START_FETCHING:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_DATA:
      return {
        ...state,
        connections: null,
        loading: false,
      };
    default:
      return state;
  }
};

export default dataReducer;
