const CoxEmailBody = (data) => {
  const text = `
    Team,<br>
  
    Please create order paperwork for the below user: <br>  
    <b>Primary Contact Name:</b> ${data.firstName} ${data.lastName} </n>
    <b>Primary Contact Phone:</b> ${data.phone} </n>
    <b>Secondary Contact phone:</b> 240-654-1884 </n>
    <b>Primary Contact Email:</b> ${data.email} </n>
    <b> Tech Contact name:</b> Glenn Taggart, 240-654-1884 gtaggart@abttelecom.com </n>
    <b>Billing Address:</b> OPTIMA Health Care, ATTN Kimberly Harriman, 4417 Corporation Lane, Virginia Beach, VA 23462 - khharrim@sentara.com 757.983.2490</n>
    <b>Full Name of Business/Account:</b> Optima Health Care</n>
    <b>Full Address of Business:</b> ${data.address} ${data.city}, ${data.state} ${data.zip}</n>
    <b>Services Being Requested:</b> 120/20 Cable, CBIG Modem, No Static IP<br>
    
    Please reply with the below information when it is available:
    <b>Due Date:</b></n>
    <b>Installation TimeFrame:</b></n>
    <b>Cox Work Order #</b></n>
    <b>Cox Acct # </b><br>


    
    <p>Glenn A. Taggart</n>
    AB&amp;T Telecom</n>
    240-654-1884 Office</n>
    240-337-8367 Fax</n>
    gtaggart@abttelecom.com</n>
    http://www.abttelecom.com</n>
    
    <p>Galileo - Hosted Voice &amp; UC Service http://www.galileo-uc.com</p>
      `;

  return text;
};

export default CoxEmailBody;
