import React, { useState, useContext, useEffect } from "react";
import {
  TextField,
  Container,
  Paper,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
} from "@mui/material";
import authContext from "../../context/auth/authContext";
import axios from "axios";
import mmddyy from "../../utils/mmddyy";

const ConnectionNotes = ({ focusedConnection }) => {
  const { user } = useContext(authContext);
  const [note, setNote] = useState("");
  const [connectionNotes, setConnectionNotes] = useState(null);
  const [usersList, setUsersList] = useState(null);

  useEffect(() => {
    const fetchConnectionNotes = async () => {
      try {
        const res = await axios.get(`/api/note/${focusedConnection._id}`);
        setConnectionNotes(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchConnectionNotes();
  }, [focusedConnection]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const res = await axios.get("/api/auth/all");
        setUsersList(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUsers();
  }, []);

  const lookupUser = (id) => {
    if (usersList !== null) {
      const fetchedUser = usersList.filter((user) => user._id === id);
      const userName = `${fetchedUser[0].firstName} ${fetchedUser[0].lastName}`;

      return userName;
    }
  };

  const handleChange = (e) => {
    setNote(e.target.value);
  };

  const handleSubmit = async () => {
    const noteRecord = {
      noteAuthor: user._id,
      connectionID: focusedConnection._id,
      note,
    };
    try {
      const res = await axios.post("/api/note", noteRecord);
      setConnectionNotes((prevConnectionNotes) => {
        return [res.data, ...prevConnectionNotes];
      });
      setNote("");
    } catch (error) {
      console.log(error);
    }
  };

  const deleteNote = (id) => {
    let updatedNotes = [];
    const filteredNotes = connectionNotes.filter((note) => note._id !== id);
    filteredNotes.map((note) => {
      updatedNotes.push(note);

      return null;
    });
    setConnectionNotes(updatedNotes);
    axios.delete(`/api/note/${id}`);
  };

  return (
    <Container>
      <Grid container sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <TextField
            id="note"
            label="Note"
            value={note}
            onChange={handleChange}
            multiline
            rows={3}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{ float: "right", mt: 1 }}
          >
            ADD
          </Button>
        </Grid>
      </Grid>

      <Paper elevaton={8} sx={{ px: 2, py: 4, mt: 2, minHeight: "55vh" }}>
        {connectionNotes !== null
          ? connectionNotes.map((note) => (
              <Card
                sx={{ mb: 2, p: 0, background: "whiteSmoke" }}
                elevation={6}
                key={note._id}
              >
                <CardContent
                  sx={{
                    px: 2,
                    py: 0,
                    "&:last-child": {
                      pb: 0,
                    },
                  }}
                >
                  <Grid container>
                    <Grid item xl={12}>
                      <Typography
                        sx={{
                          fontSize: { xs: ".5rem", xl: ".7rem" },
                          fontWeight: "bold",
                          float: "right",
                        }}
                      >
                        {mmddyy(note.createdAt)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ p: 1 }}>
                    <Typography
                      sx={{
                        fontSize: { xs: ".5rem", xl: ".9rem" },
                        fontWeight: "bold",
                      }}
                    >
                      {note.note}
                    </Typography>
                  </Grid>
                  <Grid container sx={{ mt: 1 }}>
                    <Grid item xl={6}>
                      <Typography
                        sx={{
                          fontSize: { xs: ".5rem", xl: ".7rem" },
                          fontWeight: "bold",
                        }}
                      >
                        {lookupUser(note.noteAuthor)}
                      </Typography>
                    </Grid>
                    <Grid item xl={6}>
                      <Typography
                        sx={{
                          fontSize: { xs: ".5rem", xl: ".7rem" },
                          float: "right",
                          cursor: "pointer",
                          color: "red",
                        }}
                        onClick={() => deleteNote(note._id)}
                      >
                        DELETE
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ))
          : null}
      </Paper>
    </Container>
  );
};

export default ConnectionNotes;
