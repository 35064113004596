const ComcastEmailBody = (data) => {
  const text = `
        ${data.firstName},<br>
      
        <p>We have received a request to order internet service to be used at your home.  We are working with Comcast Business to provide this service.  Please be on the lookout for phone calls from Comcast to schedule your installation. Please returned any missed calls as soon as possible to avoid orders being cancelled.<br>
        If you have any questions, please don’t hesitate to reach out to us.</p>
        
        Glenn A. Taggart</n>
        AB&amp;T Telecom</n>
        240-654-1884 Office</n>
        240-337-8367 Fax</n>
        gtaggart@abttelecom.com</n>
        http://www.abttelecom.com</n>
        
        <p>Galileo - Hosted Voice &amp; UC Service http://www.galileo-uc.com</p>
          `;

  return text;
};

export default ComcastEmailBody;
