import { Paper } from "@mui/material";
import DesktopList from "./DesktopList";
import MobileList from "./MobileList";

const ErrorsList = ({
  setFocusedError,
  setCurrentPage,
  portalErrors,
  deleteError,
}) => {
  return (
    <>
      <DesktopList
        setCurrentPage={setCurrentPage}
        setFocusedError={setFocusedError}
        portalErrors={portalErrors}
        deleteError={deleteError}
      />
      <Paper sx={{ p: 1, display: { sm: "none" }, maxWidth: "576px" }}>
        <MobileList
          setCurrentPage={setCurrentPage}
          setFocusedError={setFocusedError}
          portalErrors={portalErrors}
          deleteError={deleteError}
        />
      </Paper>
    </>
  );
};

export default ErrorsList;
