import React, { useContext } from "react";
import {
  Paper,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import axios from "axios";
import AlertContext from "../../context/alert/alertContext";
import NewAlert from "../../components/alert/NewAlert";

const UserDetails = ({ focusedUser, setCurrentPage }) => {
  const { setAlert } = useContext(AlertContext);

  const sendWelcomeEmail = () => {
    try {
      axios.post("/api/auth/welcomeEmail", focusedUser);
      setAlert(true, "Welcome Email Sent", "success");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Paper
      elevation={4}
      sx={{
        py: { xs: 1, sm: 2 },
        px: { xs: 1, sm: "20%" },
        minHeight: "100vh",
        bgcolor: "#e2e2e2",
      }}
    >
      <Button
        color="primary"
        variant="contained"
        onClick={() => setCurrentPage("list")}
        size="large"
        sx={{ mt: 1 }}
      >
        BACK
      </Button>
      <Button
        color="warning"
        variant="contained"
        onClick={() => setCurrentPage("edit")}
        size="large"
        sx={{ mt: 1, float: "right" }}
      >
        EDIT
      </Button>
      <Card elevation={6} sx={{ p: { xs: 0, sm: 4 }, mt: 3, mb: 3 }}>
        <CardContent>
          <Typography variant="h4" color="primary" sx={{ textAlign: "center" }}>
            USER DETAILS
          </Typography>
          <Table sx={{ mt: 3 }}>
            <TableBody
              sx={{
                border: "solid",
                borderWidth: "2px",
                borderColor: "#e2e2e2",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    bgcolor: "#3f3b3b",
                    color: "#e2e2e2",
                    fontWeight: "bold",
                  }}
                >
                  FIRST NAME
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {focusedUser.firstName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    bgcolor: "#3f3b3b",
                    color: "#e2e2e2",
                    fontWeight: "bold",
                  }}
                >
                  LAST NAME
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {focusedUser.lastName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    bgcolor: "#3f3b3b",
                    color: "#e2e2e2",
                    fontWeight: "bold",
                  }}
                >
                  COMPANY
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {focusedUser.company}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    bgcolor: "#3f3b3b",
                    color: "#e2e2e2",
                    fontWeight: "bold",
                  }}
                >
                  USERNAME
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {focusedUser.userName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    bgcolor: "#3f3b3b",
                    color: "#e2e2e2",
                    fontWeight: "bold",
                  }}
                >
                  STATUS
                </TableCell>
                {focusedUser.active === true ? (
                  <TableCell
                    sx={{
                      textAlign: "center",
                      color: "green",
                      fontWeight: "bold",
                    }}
                  >
                    ACTIVE
                  </TableCell>
                ) : (
                  <TableCell
                    sx={{
                      textAlign: "center",
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    DEACTIVATED
                  </TableCell>
                )}
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    bgcolor: "#3f3b3b",
                    color: "#e2e2e2",
                    fontWeight: "bold",
                  }}
                >
                  ROLE
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {focusedUser.role}
                </TableCell>
              </TableRow>
              {focusedUser.role === "Customer" && (
                <TableRow>
                  <TableCell
                    sx={{
                      bgcolor: "#2e68b1",
                      color: "#e2e2e2",
                      fontWeight: "bold",
                    }}
                  >
                    ACCOUNT #
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {focusedUser.account_num}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Button
            variant="contained"
            color="secondary"
            sx={{ float: "right", mt: 2 }}
            onClick={sendWelcomeEmail}
          >
            WELCOME
          </Button>
        </CardContent>
      </Card>
      <NewAlert />
    </Paper>
  );
};

export default UserDetails;
