import React from "react";
import { IconButton } from "@mui/material";
import FileSaver from "file-saver";
import * as XLSX from "xlsx/xlsx.mjs";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

const ExportDataExcel = ({ csvData, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const Heading = [
    {
      _id: "Record ID",
      carrier: "Carrier",
      account_num: "AccountNumber",
      lastName: "Last Name",
      firstName: "First Name",
      address: "Address",
      city: "City",
      state: "State",
      zip: "Zip",
      phone: "Phone",
      email: "Email",
      orderType: "Order Type",
      supervisor: "Supervisor",
      installDate: "Install Date",
      installRequestedBy: "Install Requested By",
      disconnectionDate: "Disconnected Date",
      disconnectionRequestedBy: "Disconnected Requested By",
      billDate: "Bill Cut Day",
      publicNotes: "Notes",
      installTimeframe: "Install Timeframe",
      providersPhoneNumber: "Providers Phone Number",
      activeStatus: "Status",
      orderPlacedDate: "Order Placed",
      createdAt: "CreatedAt",
      updatedAt: "UpdatedAt",
    },
  ];

  const wscols = [
    { wch: 5 },
    { wch: 10 },
    { wch: 25 },
    { wch: 20 },
    { wch: 25 },
    { wch: 30 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 40 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 30 },
    { wch: 30 },
  ];

  const exportToCSV = (csvData, fileName, wscols) => {
    const ws = XLSX.utils.json_to_sheet(Heading, {
      skipHeader: true,
      origin: 0, //ok
    });
    ws["!cols"] = wscols;
    XLSX.utils.sheet_add_json(ws, csvData, {
      skipHeader: true,
      origin: -1, //ok
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <IconButton
      sx={{ float: "right", color: "#3f3b3b" }}
      onClick={(e) => exportToCSV(csvData, fileName, wscols)}
    >
      <DownloadForOfflineIcon />
    </IconButton>
  );
};

export default ExportDataExcel;
