import React, { useState, useEffect } from "react";
import ErrorsList from "./ErrorsList";
import ErrorDetails from "./ErrorDetails";
import axios from "axios";

const Errors = () => {
  const [currentPage, setCurrentPage] = useState("list");
  const [focusedError, setFocusedError] = useState(null);
  const [portalErrors, setPortalErrors] = useState(null);

  const fetchPortalUsers = async () => {
    try {
      await axios.get("/api/errors/").then((response) => {
        setPortalErrors(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteError = async (id) => {
    var updatedErrorList = [];
    try {
      await axios.delete(`/api/errors/${id}`);
      const filteredErrors = portalErrors.filter((error) => error._id !== id);
      filteredErrors.map((error) => updatedErrorList.push(error));
      setPortalErrors(filteredErrors);
      setCurrentPage("list");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPortalUsers();
    //eslint-disable-next-line
  }, []);

  switch (currentPage) {
    default:
    case "list":
      return (
        <ErrorsList
          portalErrors={portalErrors}
          setFocusedError={setFocusedError}
          setCurrentPage={setCurrentPage}
          deleteError={deleteError}
        />
      );
    case "detail":
      return (
        <ErrorDetails
          focusedError={focusedError}
          setCurrentPage={setCurrentPage}
          deleteError={deleteError}
        />
      );
  }
};

export default Errors;
