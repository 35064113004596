import { Button, Container, Grid } from "@mui/material";
import React, { useContext, Fragment } from "react";
import navigationContext from "../../context/navigation/navigationContext";

const Create = () => {
  const { content, loadModule } = useContext(navigationContext);
  const create = () => {
    loadModule({ module: "Open", content: "Order" });
  };

  const acknowledge = () => {
    loadModule({ module: "Open", content: "Acknowledge" });
  };
  return (
    <Container>
      <Fragment>
        {content === "Create" ||
        content === "Open List" ||
        content === "Order" ||
        content === "Acknowledge" ? null : (
          <Grid container>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={acknowledge}
                sx={{ float: "left" }}
              >
                ACKNOWLEDGE
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={create}
                sx={{ float: "right" }}
              >
                ORDER
              </Button>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        )}
      </Fragment>
    </Container>
  );
};

export default Create;
