import React, { useEffect, useState } from "react";
import ReportExport from "../../utils/ReportExport";
import mmddyy from "../../utils/mmddyy";
import axios from "axios";

const WeeklyReport = () => {
  const [installed, setInstalled] = useState(null);
  const [open, setOpen] = useState(null);
  const [archived, setArchived] = useState(null);
  const [pending, setPending] = useState(null);
  const [disconnecting, setDisconnecting] = useState(null);
  const [researching, setResearching] = useState(null);

  const fetchData = async (filter) => {
    try {
      const res = await axios.post("/api/connections/filtered", filter);

      filter.activeStatus === "Installed" && setInstalled(res.data);
      filter.activeStatus === "Open" && setOpen(res.data);
      filter.activeStatus === "Archived" && setArchived(res.data);
      filter.activeStatus === "Pending Install" && setPending(res.data);
      filter.activeStatus === "Disconnection Needed" &&
        setDisconnecting(res.data);
      filter.activeStatus === "Research" && setResearching(res.data);

      return null;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData({ activeStatus: "Installed" });
    fetchData({ activeStatus: "Open" });
    fetchData({ activeStatus: "Archived" });
    fetchData({ activeStatus: "Pending Install" });
    fetchData({ activeStatus: "Disconnection Needed" });
    fetchData({ activeStatus: "Research" });
  }, []);

  const date = mmddyy(Date.now());

  return (
    <ReportExport
      installed={installed}
      open={open}
      pending={pending}
      disconnecting={disconnecting}
      researching={researching}
      archived={archived}
      fileName={`Optima Personnel ${date}`}
    />
  );
};

export default WeeklyReport;
