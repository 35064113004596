import React, { useState, useEffect } from "react";
import {
  Paper,
  Button,
  Card,
  CardContent,
  TextField,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  Typography,
} from "@mui/material";
import axios from "axios";

const EditUser = ({
  focusedUser,
  setFocusedUser,
  setCurrentPage,
  setPortalUsers,
  portalUsers,
}) => {
  const [editedUser, setEditedUser] = useState(null);

  useEffect(() => {
    setEditedUser(focusedUser);
    //eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    setEditedUser({ ...editedUser, [e.target.name]: e.target.value });
  };

  const saveUser = async () => {
    var updatedPortalUsers = [];
    const otherUsers = portalUsers.filter(
      (user) => user._id !== editedUser._id
    );
    updatedPortalUsers.push(editedUser);
    otherUsers.map((user) => {
      updatedPortalUsers.push(user);
      return null;
    });
    setPortalUsers(updatedPortalUsers);
    try {
      axios.put(`/api/auth/${editedUser._id}`, editedUser).then((response) => {
        setFocusedUser(editedUser);
        setCurrentPage("detail");
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Paper
      elevation={4}
      sx={{
        py: { xs: 1, sm: 2 },
        px: { xs: 1, sm: "20%" },
        minHeight: "100vh",
        bgcolor: "#e2e2e2",
      }}
    >
      <Button
        color="primary"
        variant="contained"
        onClick={() => setCurrentPage("list")}
        size="large"
        sx={{ mt: 1 }}
      >
        BACK
      </Button>
      <Button
        color="warning"
        variant="contained"
        onClick={saveUser}
        size="large"
        sx={{ mt: 1, float: "right" }}
      >
        SAVE
      </Button>
      <Card
        elevation={6}
        sx={{ py: { xs: 0, sm: 2 }, px: { xs: 0, sm: 1 }, mt: 3, mb: 3 }}
      >
        {editedUser !== null && (
          <CardContent>
            <Typography
              variant="h4"
              color="primary"
              sx={{ textAlign: "center" }}
            >
              EDIT USER
            </Typography>
            <Grid container sx={{ mt: 3 }}>
              <Grid item xs={12} md={6} sx={{ px: 1 }}>
                <TextField
                  variant="outlined"
                  name="firstName"
                  value={editedUser.firstName}
                  label="First Name"
                  sx={{ minWidth: "100%" }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ px: 1 }}>
                <TextField
                  variant="outlined"
                  name="lastName"
                  value={editedUser.lastName}
                  label="Last Name"
                  sx={{ minWidth: "100%" }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ px: 1, mt: 2 }}>
                <TextField
                  variant="outlined"
                  name="compnay"
                  value={editedUser.company}
                  label="Company"
                  sx={{ minWidth: "100%" }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ px: 1, mt: 2 }}>
                <TextField
                  variant="outlined"
                  name="userName"
                  value={editedUser.userName}
                  label="Username"
                  sx={{ minWidth: "100%" }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ px: 1, mt: 2 }}>
                <InputLabel id="role">Role</InputLabel>
                <Select
                  id="role"
                  variant="outlined"
                  name="role"
                  value={editedUser.role}
                  label="Role"
                  sx={{ minWidth: "100%" }}
                  onChange={handleChange}
                >
                  <MenuItem value="user">User</MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} md={6} sx={{ px: 1, mt: 2 }}>
                <InputLabel id="active">Status</InputLabel>
                <Select
                  id="active"
                  variant="outlined"
                  name="active"
                  value={editedUser.active}
                  sx={{ minWidth: "100%" }}
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </Grid>
              {editedUser.role === "Customer" && (
                <Grid item xs={12} md={6} sx={{ px: 1, mt: 2 }}>
                  <TextField
                    variant="outlined"
                    name="account_num"
                    value={editedUser.account_num}
                    label="Account #"
                    sx={{ minWidth: "100%" }}
                    onChange={handleChange}
                  />
                </Grid>
              )}
            </Grid>
          </CardContent>
        )}
      </Card>
    </Paper>
  );
};

export default EditUser;
