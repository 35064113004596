import {
  Paper,
  Table,
  TableHead,
  TableRow,
  Typography,
  TableCell,
  TableBody,
  Button,
  TablePagination,
  TableContainer,
} from "@mui/material";
import React, { useState } from "react";
import mmddyy from "../../utils/mmddyy";

const DesktopList = ({
  setFocusedError,
  setCurrentPage,
  portalErrors,
  deleteError,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const viewError = (error) => {
    setFocusedError(error);
    setCurrentPage("detail");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper
      sx={{ p: 8, display: { xs: "none", sm: "block" }, minHeight: "100vh" }}
    >
      <Typography
        variant="h4"
        color="primary"
        sx={{ mt: 3, textAlign: "center" }}
      >
        PORTAL ERRORS
      </Typography>
      <TableContainer sx={{ maxHeight: 700, mt: 2 }}>
        <Table size="large" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: "#e2e2e2", background: "#3f3b3b" }}>
                ID
              </TableCell>
              <TableCell
                sx={{
                  color: "#e2e2e2",
                  textAlign: "center",
                  background: "#3f3b3b",
                }}
              >
                Module
              </TableCell>
              <TableCell
                sx={{
                  color: "#e2e2e2",
                  textAlign: "center",
                  background: "#3f3b3b",
                }}
              >
                Function
              </TableCell>
              <TableCell
                sx={{
                  color: "#e2e2e2",
                  textAlign: "center",
                  background: "#3f3b3b",
                }}
              >
                Date
              </TableCell>
              <TableCell
                sx={{
                  color: "#e2e2e2",
                  textAlign: "center",
                  background: "#3f3b3b",
                }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {portalErrors !== null && portalErrors !== undefined
              ? portalErrors
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((error) => (
                    <TableRow hover key={error._id}>
                      <TableCell onClick={() => viewError(error)}>
                        {error._id}
                      </TableCell>
                      <TableCell
                        sx={{ textAlign: "center", cursor: "pointer" }}
                        onClick={() => viewError(error)}
                      >
                        {error.module}
                      </TableCell>
                      <TableCell
                        sx={{ textAlign: "center", cursor: "pointer" }}
                        onClick={() => viewError(error)}
                      >
                        {error.function}
                      </TableCell>
                      <TableCell
                        sx={{ textAlign: "center", cursor: "pointer" }}
                      >
                        {mmddyy(error.createdAt)}
                      </TableCell>
                      <TableCell>
                        <Button
                          color="error"
                          variant="contained"
                          onClick={() => deleteError(error._id)}
                          sx={{ float: "right" }}
                        >
                          DELETE
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      {portalErrors !== null &&
      portalErrors !== undefined &&
      portalErrors.length > 12 ? (
        <TablePagination
          rowsPerPageOptions={[12, 25, 50, 100]}
          component="div"
          count={portalErrors.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            color: "#e2e2e2",
            backgroundColor: "#3f3b3b",
          }}
        />
      ) : null}
    </Paper>
  );
};

export default DesktopList;
