import React, { useState, useContext, useEffect } from "react";
import {
  TextField,
  Container,
  Paper,
  Button,
  Typography,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import authContext from "../../context/auth/authContext";
import axios from "axios";
import mmddyy from "../../utils/mmddyy";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

const ConnectionPayments = ({ focusedConnection }) => {
  const { user } = useContext(authContext);

  const [connectionPayments, setConnectionPayments] = useState(null);
  const [usersList, setUsersList] = useState(null);

  const initialValues = {
    submitDate: Date.now(),
    paymentAmount: 0.0,
    invoiceNumber: "",
    memo: "",
  };

  const [payment, setPayment] = useState(initialValues);

  useEffect(() => {
    const fetchConnectionPayments = async () => {
      try {
        const res = await axios.get(`/api/payments/${focusedConnection._id}`);
        setConnectionPayments(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchConnectionPayments();
  }, [focusedConnection]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const res = await axios.get("/api/auth/all");
        setUsersList(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUsers();
  }, []);

  const lookupUser = (id) => {
    if (usersList !== null) {
      const fetchedUser = usersList.filter((user) => user._id === id);
      const userName = `${fetchedUser[0].firstName} ${fetchedUser[0].lastName}`;

      return userName;
    }
  };

  const handleChange = (e) => {
    setPayment({ ...payment, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    const paymentRecord = {
      submittedBy: user._id,
      connectionID: focusedConnection._id,
      submitDate: payment.submitDate,
      paymentAmount: payment.paymentAmount,
      invoiceNumber: payment.invoiceNumber,
      memo: payment.memo,
      carrier: focusedConnection.carrier,
    };
    try {
      const res = await axios.post("/api/payments", paymentRecord);
      setConnectionPayments((prevConnectionPayments) => {
        return [res.data, ...prevConnectionPayments];
      });
      setPayment(initialValues);
    } catch (error) {
      console.log(error);
    }
  };

  const deletePayment = (id) => {
    let updatedPayments = [];
    const filteredPayments = connectionPayments.filter(
      (payment) => payment._id !== id
    );
    filteredPayments.map((payment) => {
      updatedPayments.push(payment);

      return null;
    });
    setConnectionPayments(updatedPayments);
    axios.delete(`/api/payments/${id}`);
  };

  const convertToDefEventPara = (name, value) => ({
    target: {
      name,
      value,
    },
  });
  return (
    <Container>
      <Grid container>
        <Grid item xl={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Submit Date"
              value={payment.submitDate}
              onChange={(date) =>
                handleChange(convertToDefEventPara("submitDate", date))
              }
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xl={2}>
          <TextField
            id="paymentAmount"
            name="paymentAmount"
            label="Amount"
            value={payment.paymentAmount}
            onChange={handleChange}
            sx={{ ml: 1 }}
          />
        </Grid>
        <Grid item xl={2}>
          <TextField
            id="invoiceNumber"
            name="invoiceNumber"
            label="Invoice #"
            value={payment.invoiceNumber}
            onChange={handleChange}
            sx={{ ml: 1 }}
          />
        </Grid>
        <Grid item xl={6}>
          <TextField
            id="memo"
            name="memo"
            label="Memo"
            value={payment.memo}
            onChange={handleChange}
            sx={{ width: "100%", ml: 1 }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{ float: "right", mt: 1 }}
          >
            ADD
          </Button>
        </Grid>
      </Grid>

      <Paper elevaton={8} sx={{ px: 1, py: 2, mt: 2, minHeight: "59vh" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SUBMIT DATE</TableCell>
              <TableCell>AMOUNT</TableCell>
              <TableCell>INVOICE #</TableCell>
              <TableCell>CARRIER</TableCell>
              <TableCell>MEMO</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {connectionPayments !== null &&
              connectionPayments.map((payment) => (
                <TableRow key={payment._id}>
                  <TableCell>{mmddyy(payment.submitDate)}</TableCell>
                  <TableCell>${payment.paymentAmount}</TableCell>
                  <TableCell>{payment.invoiceNumber}</TableCell>
                  <TableCell>{payment.carrier}</TableCell>
                  <TableCell
                    sx={{
                      width: "45%",
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                    }}
                  >
                    {payment.memo}
                  </TableCell>
                  <TableCell
                    onClick={() => deletePayment(payment._id)}
                    sx={{
                      textAlign: "right",
                      cursor: "pointer",
                      color: "red",
                    }}
                  >
                    X
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
    </Container>
  );
};

export default ConnectionPayments;
