import React, { useEffect, useState, useContext } from "react";
import navigationContext from "../../context/navigation/navigationContext";
import dataContext from "../../context/data/dataContext";
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import NewAlert from "../alert/NewAlert";

const ConnectionForm = ({ focusedConnection, module }) => {
  const { loadModule } = useContext(navigationContext);
  const { editData } = useContext(dataContext);
  const [editedConnection, setEditedConnection] = useState(null);

  useEffect(() => {
    setEditedConnection(focusedConnection);
    //eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    setEditedConnection({
      ...editedConnection,
      [e.target.name]: e.target.value,
    });
  };

  const cancel = () => {
    setEditedConnection(focusedConnection);
    loadModule({ module: module, content: "Open List" });
  };

  const submit = (e) => {
    e.preventDefault();
    editData(editedConnection);
    loadModule({ module: module, content: "Open List" });
  };

  const convertToDefEventPara = (name, value) => ({
    target: {
      name,
      value,
    },
  });

  return (
    <Container>
      {editedConnection !== null && (
        <Paper
          elevation={8}
          sx={{ py: 2, px: 2, mt: 2, mb: 4, backgroundColor: "#EEEEEE" }}
        >
          <Grid container>
            <Grid item xs={12} xl={6}>
              <Typography variant="h6" color="primary">
                EDIT CONNECTION
              </Typography>
            </Grid>
            <Grid item xs={12} xl={6}>
              <Select
                size="small"
                sx={{
                  width: "40%",
                  float: "right",
                  backgroundColor: "white",
                  color: "#feb062",
                  fontWeight: "bold",
                }}
                id="Status"
                name="activeStatus"
                defaultValue={editedConnection.activeStatus}
                onChange={handleChange}
              >
                <MenuItem value="New">New</MenuItem>
                <MenuItem value="Pending Install">Pending Install</MenuItem>
                <MenuItem value="Installed">Installed</MenuItem>
                <MenuItem value="Archived">Archived</MenuItem>
                <MenuItem value="Move Needed">Move Needed</MenuItem>
                <MenuItem value="Disconnection Needed">
                  Disconnection Needed
                </MenuItem>
                <MenuItem value="Research">Research</MenuItem>
                <MenuItem value="Virginia Premier">Virginia Premier</MenuItem>
              </Select>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 1 }}>
            <Grid item xs={12} xl={6}>
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid item xs={12} xl={6}>
                      <TextField
                        sx={{ width: "98%" }}
                        variant="outlined"
                        label="First Name"
                        name="firstName"
                        defaultValue={editedConnection.firstName}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} xl={6}>
                      <TextField
                        sx={{ width: "98%", mt: { xs: 1, xl: 0 } }}
                        variant="outlined"
                        name="lastName"
                        label="Last Name"
                        defaultValue={editedConnection.lastName}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        sx={{ width: "99%", mt: 1 }}
                        variant="outlined"
                        name="address"
                        label="Address"
                        defaultValue={editedConnection.address}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} xl={8}>
                      <TextField
                        sx={{ width: "99%", mt: 1 }}
                        variant="outlined"
                        name="city"
                        label="City"
                        defaultValue={editedConnection.city}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} xl={2}>
                      <TextField
                        sx={{ width: "99%", mt: 1 }}
                        variant="outlined"
                        name="state"
                        label="State"
                        defaultValue={editedConnection.state}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} xl={2}>
                      <TextField
                        sx={{ width: "99%", mt: 1 }}
                        variant="outlined"
                        name="zip"
                        label="Zip"
                        defaultValue={editedConnection.zip}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        sx={{ width: "99%", mt: 1 }}
                        variant="outlined"
                        label="Phone"
                        name="phone"
                        defaultValue={editedConnection.phone}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        sx={{ width: "99%", mt: 1 }}
                        variant="outlined"
                        name="email"
                        label="Email"
                        defaultValue={editedConnection.email}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} xl={6}>
              <Card sx={{ ml: { xl: 1 } }}>
                <CardContent>
                  <Grid container>
                    <Grid item xs={12}>
                      <TextField
                        sx={{ width: "99%" }}
                        variant="outlined"
                        name="carrier"
                        label="carrier"
                        defaultValue={editedConnection.carrier}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        sx={{ width: "99%", mt: 1 }}
                        variant="outlined"
                        label="Account Number"
                        name="account_num"
                        defaultValue={editedConnection.account_num}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        sx={{ width: "99%", mt: 1 }}
                        variant="outlined"
                        name="providersPhoneNumber"
                        label="Support Phone #"
                        defaultValue={editedConnection.providersPhoneNumber}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Installed Date"
                          name="installDate"
                          value={editedConnection.installDate}
                          onChange={(date) =>
                            handleChange(
                              convertToDefEventPara("installDate", date)
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{ minWidth: "99%", mt: 1 }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        sx={{ width: "99%", mt: 1 }}
                        variant="outlined"
                        name="installTimeframe"
                        label="Timeframe"
                        defaultValue={editedConnection.installTimeframe}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        sx={{ width: "99%", mt: 1 }}
                        variant="outlined"
                        name="billDate"
                        label="Bill Cut Day"
                        defaultValue={editedConnection.billDate}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid item xs={12} xl={6}>
                      <TextField
                        sx={{ width: "100%", mt: 1 }}
                        variant="outlined"
                        name="orderType"
                        label="Order Type"
                        defaultValue={editedConnection.orderType}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} xl={6}>
                      <TextField
                        sx={{ width: "99%", mt: 1, ml: { xl: 1 } }}
                        variant="outlined"
                        name="supervisor"
                        label="Supervisor"
                        defaultValue={editedConnection.supervisor}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} xl={6}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Order Placed"
                          name="orderPlacedDate"
                          value={editedConnection.orderPlacedDate}
                          onChange={(date) =>
                            handleChange(
                              convertToDefEventPara("orderPlacedDate", date)
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{ minWidth: "100%", mt: 1 }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} xl={6}>
                      <TextField
                        sx={{ width: "99%", mt: 1, ml: { xl: 1 } }}
                        variant="outlined"
                        name="installRequestedBy"
                        label="Requested By"
                        defaultValue={editedConnection.installRequestedBy}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} xl={6}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Disconnection Date"
                          name="disconnectionDate"
                          value={editedConnection.disconnectionDate}
                          onChange={(date) =>
                            handleChange(
                              convertToDefEventPara("disconnectionDate", date)
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{ minWidth: "100%", mt: 1 }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} xl={6}>
                      <TextField
                        sx={{ width: "99%", mt: 1, ml: { xl: 1 } }}
                        variant="outlined"
                        name="disconnectionRequestedBy"
                        label="Requested By"
                        defaultValue={editedConnection.disconnectionRequestedBy}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Card>
                <CardContent>
                  <TextField
                    sx={{ width: "99%", mt: 1, ml: { xl: 1 } }}
                    variant="outlined"
                    name="publicNotes"
                    multiline
                    rows="4"
                    label="Comments"
                    defaultValue={editedConnection.publicNotes}
                    onChange={handleChange}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={6}>
              <Button variant="contained" color="error" onClick={cancel}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={submit}
                sx={{ float: "right" }}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )}
      <NewAlert />
    </Container>
  );
};

export default ConnectionForm;
