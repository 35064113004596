export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_MODULE = "SET_MODULE";
export const SET_CONTENT = "SET_CONTENT";
export const SET_ALERT = "SET_ALERT";
export const GET_DATA = "GET_DATA";
export const CLEAR_DATA = "CLEAR_DATA";
export const START_FETCHING = "START_FETCHING";
export const CREATE_DATA = "CREATE_DATA";
export const EDIT_DATA = "EDIT_DATA";
export const DELETE_DATA = "DELETE_DATA";
