import React from "react";
import { Container, Grid, IconButton, Typography } from "@mui/material";
import FileSaver from "file-saver";
import * as XLSX from "xlsx/xlsx.mjs";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

const ReportExport = ({
  installed,
  open,
  archived,
  pending,
  fileName,
  disconnecting,
  researching,
}) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const wscols = [
    { wch: 5 },
    { wch: 10 },
    { wch: 25 },
    { wch: 20 },
    { wch: 25 },
    { wch: 30 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 40 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 30 },
    { wch: 30 },
  ];

  console.log(installed);

  const exportToCSV = (
    installed,
    open,
    archived,
    pending,
    disconnecting,
    researching,
    fileName,
    wscols
  ) => {
    const Heading = [
      {
        _id: "Record ID",
        carrier: "Carrier",
        account_num: "AccountNumber",
        lastName: "Last Name",
        firstName: "First Name",
        address: "Address",
        city: "City",
        state: "State",
        zip: "Zip",
        phone: "Phone",
        email: "Email",
        orderType: "Order Type",
        supervisor: "Supervisor",
        billDate: "Bill Cut Date",
        installDate: "Install Date",
        installRequestedBy: "Install Requested By",
        disconnectionDate: "Disconnected Date",
        disconnectionRequestedBy: "Disconnected Requested By",
        publicNotes: "Notes",
        installTimeframe: "Install Timeframe",
        providersPhoneNumber: "Providers Phone Number",
        activeStatus: "Status",
        orderPlacedDate: "Order Placed",
        createdAt: "CreatedAt",
        updatedAt: "UpdatedAt",
      },
    ];

    const installedData = XLSX.utils.json_to_sheet(Heading, {
      skipHeader: true,
      origin: 0, //ok
    });
    installedData["!cols"] = wscols;
    XLSX.utils.sheet_add_json(installedData, installed, {
      skipHeader: true,
      origin: -1, //ok
    });
    const openData = XLSX.utils.json_to_sheet(Heading, {
      skipHeader: true,
      origin: 0, //ok
    });
    openData["!cols"] = wscols;
    XLSX.utils.sheet_add_json(openData, open, {
      skipHeader: true,
      origin: -1, //ok
    });
    const pendingData = XLSX.utils.json_to_sheet(Heading, {
      skipHeader: true,
      origin: 0, //ok
    });
    pendingData["!cols"] = wscols;
    XLSX.utils.sheet_add_json(pendingData, pending, {
      skipHeader: true,
      origin: -1, //ok
    });
    const disconnectingData = XLSX.utils.json_to_sheet(Heading, {
      skipHeader: true,
      origin: 0, //ok
    });
    disconnectingData["!cols"] = wscols;
    XLSX.utils.sheet_add_json(disconnectingData, disconnecting, {
      skipHeader: true,
      origin: -1, //ok
    });
    const researchingData = XLSX.utils.json_to_sheet(Heading, {
      skipHeader: true,
      origin: 0, //ok
    });
    researchingData["!cols"] = wscols;
    XLSX.utils.sheet_add_json(researchingData, researching, {
      skipHeader: true,
      origin: -1, //ok
    });
    const archivedData = XLSX.utils.json_to_sheet(Heading, {
      skipHeader: true,
      origin: 0, //ok
    });
    archivedData["!cols"] = wscols;
    XLSX.utils.sheet_add_json(archivedData, archived, {
      skipHeader: true,
      origin: -1, //ok
    });
    const wb = {
      Sheets: {
        Installed: installedData,
        Open: openData,
        Pending: pendingData,
        Disconnecting: disconnectingData,
        Researching: researchingData,
        Archived: archivedData,
      },
      SheetNames: [
        "Installed",
        "Open",
        "Pending",
        "Disconnecting",
        "Researching",
        "Archived",
      ],
    };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Container>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: "10%",
        }}
      >
        <Typography variant="h4" color="primary">
          DOWNLOAD WEEKLY REPORT
        </Typography>
        <IconButton
          sx={{ float: "right", color: "#3f3b3b" }}
          onClick={(e) =>
            exportToCSV(
              installed,
              open,
              archived,
              pending,
              disconnecting,
              researching,
              fileName,
              wscols
            )
          }
        >
          <DownloadForOfflineIcon sx={{ fontSize: "75px" }} />
        </IconButton>
      </Grid>
    </Container>
  );
};

export default ReportExport;
