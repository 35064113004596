import React, { useEffect, useState, useContext } from 'react';
import { Navigate } from 'react-router';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import NewAlert from '../../components/alert/NewAlert';
import AlertContext from '../../context/alert/alertContext';
import AuthContext from '../../context/auth/authContext';
import axios from 'axios';

const UserAuthentication = ({ setAuthenticationState }) => {
  const { error, clearErrors, isAuthenticated, loadUser } =
    useContext(AuthContext);
  const { setAlert } = useContext(AlertContext);

  const [user, setUser] = useState({
    userName: '',
    password: '',
  });

  const { userName, password } = user;

  const onChange = (e) => setUser({ ...user, [e.target.name]: e.target.value });

  useEffect(() => {
    if (localStorage.auth_token) {
      loadUser();
    }
    if (error === 'Invalid Credentials') {
      setAlert(true, 'Invalid Credentials', 'error');
      clearErrors();
    }
    if (error === 'Login Disabled') {
      setAlert(true, 'Login Disabled', 'error');
      clearErrors();
    }
    // eslint-disable-next-line
  }, [error, isAuthenticated]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userName === '' || password === '') {
      console.log('ERROR');
      setAlert(true, 'Please fill in all fields', 'error');
    } else {
      try {
        const res = await axios.post('/api/auth', user);
        if (res.status === 200) {
          setAuthenticationState('pin');
        }
      } catch (error) {
        setAlert(true, 'Invalid User & Password', 'error');
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 9,
          marginBottom: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#FFF',
          borderRadius: '15px',
          p: 4,
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: '#3f3b3b' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          CONNECTION TRACKER
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="userName"
            name="userName"
            autoComplete="userName"
            onChange={onChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={onChange}
          />

          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 2, float: 'right' }}
            onClick={handleSubmit}
          >
            Sign In
          </Button>

          <Grid container sx={{ mt: 1 }}>
            <Grid item xs>
              <Link href="/passwordReset" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </Box>
        <NewAlert />
      </Box>
      {isAuthenticated && <Navigate to="/" />}
    </Container>
  );
};

export default UserAuthentication;
