import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../context/data/dataContext";
import NavigationContext from "../../context/navigation/navigationContext";
import ConnectionTable from "../../components/tables/ConnectionTable";
import CreateForm from "../../components/forms/CreateForm";
import Connection from "../connection/Connection";

const ActiveConnections = () => {
  const { fetchFilteredData, clearData } = useContext(DataContext);
  const { content } = useContext(NavigationContext);
  const [focusedConnection, setFocusedConnection] = useState(null);
  const [searchBar, setSearchBar] = useState("");
  const [displayConnections, setDisplayConnections] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("lastName");

  useEffect(() => {
    fetchFilteredData({ activeStatus: "Installed" });

    return () => {
      clearData();
    };
    //eslint-disable-next-line
  }, []);

  switch (content) {
    default:
    case "Active List":
      return (
        <ConnectionTable
          searchBar={searchBar}
          setSearchBar={setSearchBar}
          displayConnections={displayConnections}
          setDisplayConnections={setDisplayConnections}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          setFocusedConnection={setFocusedConnection}
          module="Active"
        />
      );
    case "Create":
      return <CreateForm />;
    case "Edit":
      return (
        <Connection
          focusedConnection={focusedConnection}
          setFocusedConnection={setFocusedConnection}
          module="Active"
        />
      );
  }
};

export default ActiveConnections;
