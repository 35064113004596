import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Paper,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import mmddyy from "../../utils/mmddyy";

const MobileList = ({
  setFocusedError,
  setCurrentPage,
  portalErrors,
  deleteError,
}) => {
  const viewError = (error) => {
    setFocusedError(error);
    setCurrentPage("detail");
  };
  return (
    <Paper sx={{ display: { sm: "none" }, p: 1, pb: 4 }}>
      <Grid container>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="success"
            onClick={() => setCurrentPage("create")}
            sx={{ float: "right", mr: { xs: 0, sm: 3 } }}
          >
            ADD
          </Button>
        </Grid>
      </Grid>
      {portalErrors !== null && portalErrors !== undefined
        ? portalErrors.map((error) => (
            <Card elevation={6} sx={{ p: 0, mt: 2 }} key={error._id}>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: "red" }} aria-label="status">
                    <AccountCircleIcon />
                  </Avatar>
                }
                title={<Typography variant="h5">{error.module}</Typography>}
              />

              <CardContent>
                <Typography variant="h6">
                  Date: {mmddyy(error.createdAt)}
                </Typography>
                <Typography variant="h6">
                  {" "}
                  Function: {error.function}
                </Typography>
                <Typography variant="h6">Note 1: {error.note1}</Typography>
                <Typography variant="h6">Note 2: {error.note2}</Typography>
                <Typography variant="h6">Error:</Typography>
                <Typography variant="body2">{error.error_message}</Typography>
                <Button
                  color="error"
                  variant="contained"
                  onClick={() => deleteError(error._id)}
                  sx={{ mt: 2, mb: 2, mr: 1, float: "right" }}
                >
                  DELETE
                </Button>
              </CardContent>
            </Card>
          ))
        : null}
    </Paper>
  );
};

export default MobileList;
