import React, { useReducer } from "react";
import DataContext from "./dataContext";
import dataReducer from "./dataReducer";
import {
  GET_DATA,
  CLEAR_DATA,
  START_FETCHING,
  CREATE_DATA,
  EDIT_DATA,
  DELETE_DATA,
} from "../types";
import axios from "axios";

const DataState = (props) => {
  const initalState = {
    connections: null,
    fetching: false,
  };

  const [state, dispatch] = useReducer(dataReducer, initalState);

  const createConnection = async (data) => {
    try {
      const res = await axios.post("/api/connections", data);
      dispatch({
        type: CREATE_DATA,
        payload: res.data[0],
      });
    } catch (error) {
      console.log(error);
    }
  };

  const startFetching = () => {
    dispatch({
      type: START_FETCHING,
      payload: true,
    });
  };

  const editData = async (data) => {
    startFetching();
    try {
      const res = await axios.put(`/api/connections/${data._id}`, data);
      dispatch({
        type: EDIT_DATA,
        payload: res.data[0],
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async (e) => {
    startFetching();
    try {
      const res = await axios.get("/api/connections");
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFilteredData = async (filter) => {
    startFetching();
    try {
      const res = await axios.post("/api/connections/filtered", filter);
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteData = async (id) => {
    try {
      const res = await axios.delete(`/api/connections/${id}`);
      dispatch({
        type: DELETE_DATA,
        payload: id,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const clearData = (e) => {
    dispatch({
      type: CLEAR_DATA,
    });
  };

  return (
    <DataContext.Provider
      value={{
        connections: state.connections,
        fetching: state.fetching,
        fetchData,
        clearData,
        createConnection,
        fetchFilteredData,
        editData,
        deleteData,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};

export default DataState;
