import { Button, Grid } from "@mui/material";
import React, { useContext, Fragment } from "react";
import navigationContext from "../../context/navigation/navigationContext";

const Create = () => {
  const { content, loadModule } = useContext(navigationContext);
  const create = () => {
    loadModule({ module: "Open", content: "Create" });
  };
  return (
    <Fragment>
      {content === "Create" ||
      content === "Edit" ||
      content === "Order" ||
      content === "Acknowledge" ? null : (
        <Grid container>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              onClick={create}
              sx={{ float: "right", mr: { xs: 1, xl: 4 } }}
            >
              CREATE
            </Button>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

export default Create;
