import React, { useState, useContext } from "react";
import {
  Button,
  Select,
  MenuItem,
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import AlertContext from "../../context/alert/alertContext";
import NewAlert from "../../components/alert/NewAlert";
import axios from "axios";

const initialValues = {
  orderType: "Choose One",
  supervisor: "",
  carrier: "",
  lastName: "",
  firstName: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  phone: "",
  email: "",
  installRequestedBy: "",
  activeStatus: "New",
  account_num: "",
  publicNotes: "",
  providersPhoneNumber: "",
};

const OnlineOrderForm = () => {
  const { setAlert } = useContext(AlertContext);
  const [newConnection, setNewConnection] = useState(initialValues);
  const [submissionStatus, setSubmissionStatus] = useState("pending");

  const handleChange = (e) =>
    setNewConnection({ ...newConnection, [e.target.name]: e.target.value });

  const handleSubmit = async (e) => {
    try {
      await axios.post(`/api/connections/newOrder`, newConnection).then(() => {
        setAlert(true, "Order Submitted", "success");
        setNewConnection(initialValues);
        setSubmissionStatus("submitted");
        window.scroll(0, 0);
        setTimeout(() => {
          setSubmissionStatus("pending");
        }, 5000);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const cancel = () => {
    setNewConnection(initialValues);
  };
  switch (submissionStatus) {
    default:
    case "submitted":
      return (
        <Container component="main" maxWidth="lg">
          <Box
            sx={{
              marginTop: 2,
              marginBottom: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#3f3b3b",
              borderRadius: "15px",
              px: { xs: 0.5, lg: 3, xl: 4 },
              py: { xs: 2, lg: 3, xl: 4 },
            }}
          >
            <Typography
              component="h1"
              variant="h6"
              sx={{ textAlign: "center", color: "#feb062" }}
            >
              OPTIMA WORK @ HOME INTERNET
            </Typography>
            <Typography
              component="h1"
              variant="h6"
              sx={{ textAlign: "center", color: "#feb062" }}
            >
              CONNECTION FORM
            </Typography>

            <Box component="form" noValidate sx={{ mt: 4 }}>
              <Grid container sx={{ mt: 1 }}>
                <Grid item xs={12}>
                  <Card elevation={8} sx={{ mt: 2 }}>
                    <CardContent>
                      <Typography variant="h6">
                        YOUR REQUEST HAS BEEN SUBMITTED
                      </Typography>
                      <Typography
                        sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
                      >
                        Someone from AB&T Telecom will confirm receipt of this
                        request and let you know what the next step will be for
                        processing this order. If you have any questions please
                        email optimainternet@abttelecom.com.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
            <NewAlert />
          </Box>
        </Container>
      );
    case "pending":
      return (
        <Container component="main" maxWidth="lg">
          <Box
            sx={{
              marginTop: 2,
              marginBottom: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#3f3b3b",
              borderRadius: "15px",
              px: { xs: 0.5, lg: 3, xl: 4 },
              py: { xs: 2, lg: 3, xl: 4 },
            }}
          >
            <Typography
              component="h1"
              variant="h6"
              sx={{ textAlign: "center", color: "#feb062" }}
            >
              OPTIMA WORK @ HOME INTERNET
            </Typography>
            <Typography
              component="h1"
              variant="h6"
              sx={{ textAlign: "center", color: "#feb062" }}
            >
              CONNECTION FORM
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "0.8rem", sm: "1rem" },
                textAlign: "center",
                color: "#feb062",
                px: 2,
              }}
            >
              (Please answer all of the below questions in order to order new
              internet services for work.)
            </Typography>
            <Box component="form" noValidate sx={{ mt: 4 }}>
              <Grid container sx={{ mt: 1 }}>
                <Grid item xs={12}>
                  <Card elevation={8}>
                    <CardContent>
                      <Typography variant="h6">ORDER TYPE:</Typography>
                      <Select
                        size="small"
                        sx={{
                          width: "49%",
                          backgroundColor: "white",
                          color: "#feb062",
                          fontWeight: "bold",
                        }}
                        id="Status"
                        name="orderType"
                        value={newConnection.orderType}
                        onChange={handleChange}
                      >
                        <MenuItem value="Choose One">Choose One</MenuItem>
                        <MenuItem value="New">NEW</MenuItem>
                        <MenuItem value="Move">MOVE</MenuItem>
                      </Select>
                      <TextField
                        size="small"
                        sx={{
                          width: "49%",
                          mt: { xs: 1, md: 0 },
                          ml: { xs: 0, md: 1 },
                        }}
                        variant="outlined"
                        label="Supervisor Name"
                        name="supervisor"
                        value={newConnection.supervisor}
                        onChange={handleChange}
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card elevation={8} sx={{ mt: 2 }}>
                    <CardContent>
                      <Typography variant="h6">CONTACT INFORMATION:</Typography>
                      <Typography
                        sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
                      >
                        Please enter your contact information. Please only enter
                        your Sentara.com email address. If you have not received
                        that address yet. Please come back once you have
                        received this email address.
                      </Typography>
                      <Grid container>
                        <Grid item xs={12}>
                          <TextField
                            sx={{ width: "99%", mt: 1 }}
                            variant="outlined"
                            label="First Name"
                            name="firstName"
                            value={newConnection.firstName}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            sx={{ width: "99%", mt: 1 }}
                            variant="outlined"
                            name="lastName"
                            label="Last Name"
                            value={newConnection.lastName}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            sx={{ width: "99%", mt: 1 }}
                            variant="outlined"
                            label="Phone"
                            name="phone"
                            value={newConnection.phone}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            sx={{ width: "99%", mt: 1 }}
                            variant="outlined"
                            name="email"
                            label="Email"
                            value={newConnection.email}
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card elevation={8} sx={{ mt: 2 }}>
                    <CardContent>
                      <Typography variant="h6">
                        INSTALLATION ADDRESS:
                      </Typography>
                      <Typography
                        sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
                      >
                        Please enter the address that we will need to install
                        the internet connection at:
                      </Typography>
                      <Grid container>
                        <Grid item xs={12}>
                          <TextField
                            sx={{ width: "99%", mt: 1 }}
                            variant="outlined"
                            name="address"
                            label="Address"
                            value={newConnection.address}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <TextField
                            sx={{ width: "99%", mt: 1 }}
                            variant="outlined"
                            name="city"
                            label="City"
                            value={newConnection.city}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <TextField
                            sx={{ width: "99%", mt: 1 }}
                            variant="outlined"
                            name="state"
                            label="State"
                            value={newConnection.state}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <TextField
                            sx={{ width: "99%", mt: 1 }}
                            variant="outlined"
                            name="zip"
                            label="Zip"
                            value={newConnection.zip}
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card elevation={8} sx={{ mt: 2 }}>
                    <CardContent>
                      <Typography variant="h6">LOCATION:</Typography>
                      <Typography
                        sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
                      >
                        Please describe where in your home this connection will
                        be installed
                      </Typography>
                      <Grid container>
                        <Grid item xs={12}>
                          <TextField
                            sx={{ width: "99%", mt: 1 }}
                            variant="outlined"
                            name="publicNotes"
                            label="Location Notes"
                            value={newConnection.publicNotes}
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card elevation={8} sx={{ mt: 2 }}>
                    <CardContent>
                      <Typography variant="h6">INTERNET PROVIDER:</Typography>
                      <Typography
                        sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
                      >
                        Please tell us what you currently have installed in your
                        home for personal internet access or what providers you
                        know can service your address.(Examples: Cox, Comcast,
                        Xfinity, Spectrum, Centurylink or others)
                      </Typography>
                      <Grid container>
                        <Grid item xs={12}>
                          <TextField
                            sx={{ width: "99%", mt: 1 }}
                            variant="outlined"
                            name="carrier"
                            label="carrier"
                            value={newConnection.carrier}
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card elevation={8} sx={{ mt: 2 }}>
                    <CardContent>
                      <Typography variant="h6">NEXT STEP:</Typography>
                      <Typography
                        sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
                      >
                        Upon submitting this order form. Someone from AB&T
                        Telecom will confirm receipt of this request and let you
                        know what the next step will be for processing this
                        order. If you have any questions please email
                        optimainternet@abttelecom.com.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              <Grid container sx={{ mt: 3, px: 2 }}>
                <Grid item xs={6}>
                  <Button variant="contained" color="error" onClick={cancel}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    sx={{
                      float: "right",
                      backgroundColor: "#feb062",
                      color: "#3f3b3b",
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <NewAlert />
          </Box>
        </Container>
      );
  }
};

export default OnlineOrderForm;
