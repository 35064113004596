import React, { useReducer } from 'react';
import NavigationContext from './navigationContext';
import navigatationReducer from './navigationReducer';
import { SET_MODULE, SET_CONTENT } from '../types';

const NavigationState = (props) => {
  const initalState = {
    menuOpen: true,
    module: 'menu',
    content: 'main',
  };

  const [state, dispatch] = useReducer(navigatationReducer, initalState);

  const loadModule = (e) => {
    dispatch({
      type: SET_MODULE,
      payload: e,
    });
  };
  const loadContent = (e) => {
    dispatch({
      type: SET_CONTENT,
      payload: e,
    });
  };

  return (
    <NavigationContext.Provider
      value={{
        menuOpen: state.menuOpen,
        module: state.module,
        content: state.content,
        loadModule,
        loadContent,
      }}
    >
      {props.children}
    </NavigationContext.Provider>
  );
};

export default NavigationState;
