import {
  Button,
  Grid,
  Paper,
  Typography,
  Container,
  Card,
  CardContent,
  TextField,
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import NavContext from "../../context/navigation/navigationContext";
import AlertContext from "../../context/alert/alertContext";
import ComcastEmailBody from "../emailTemplates/ComcastEmailBody";
import CoxEmailBody from "../emailTemplates/CoxEmailBody";
import SpectrumOrderBody from "../emailTemplates/SpectrumOrderBody";
import axios from "axios";

const SendOrder = ({ focusedConnection }) => {
  const { setAlert } = useContext(AlertContext);
  const { loadModule } = useContext(NavContext);
  const [orderEmail, setOrderEmail] = useState({
    to: "",
    cc: "gtaggart@abttelecom.com",
    bcc: "",
    from: "gtaggart@abttelecom.com",
    subject: "",
    text: "",
  });

  useEffect(() => {
    if (focusedConnection !== null && focusedConnection !== undefined) {
      if (focusedConnection.carrier === "Comcast") {
        const body = ComcastEmailBody(focusedConnection);
        setOrderEmail({
          ...orderEmail,
          to: "gtaggart@abttelecom.com",
          subject: `Comcast Order - Optima Health - ${focusedConnection.firstName} ${focusedConnection.lastName} `,
          text: body,
        });
      }
      if (focusedConnection.carrier === "Cox") {
        const body = CoxEmailBody(focusedConnection);
        setOrderEmail({
          ...orderEmail,
          to: "sentaraaccountteam@cox.com",
          bcc: "",
          subject: `Cox Order - Optima Health - ${focusedConnection.firstName} ${focusedConnection.lastName} `,
          text: body,
        });
      }
      if (focusedConnection.carrier === "Spectrum") {
        const body = SpectrumOrderBody(focusedConnection);
        setOrderEmail({
          ...orderEmail,
          to: "orders@connectivitysource.com",
          bcc: "",
          subject: `Spectrum Order - Optima Health - ${focusedConnection.firstName} ${focusedConnection.lastName} `,
          text: body,
        });
      }
    }
    //eslint-disable-next-line
  }, [focusedConnection]);

  const handleChange = (e) => {
    setOrderEmail({ ...orderEmail, [e.target.name]: e.target.value });
  };

  const cancel = () => {
    loadModule({ module: "Open", content: "Edit" });
  };

  const send = async () => {
    await axios
      .post("/api/sendEmail", orderEmail)
      .then((response) => {
        if (response.status === 200) {
          setAlert(true, "Email Sent", "success");
          loadModule({ module: "Open", content: "Edit" });
        }
      })
      .catch((error) => {
        setAlert(true, "EMAIL FAILED", "error");
      });
  };
  return (
    <Container>
      <Paper
        elevation={8}
        sx={{ py: 2, px: 2, mt: 2, mb: 4, backgroundColor: "#EEEEEE" }}
      >
        <Grid container>
          <Grid item xs={6}>
            <Button variant="contained" color="error" onClick={cancel}>
              CANCEL
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="secondary"
              onClick={send}
              sx={{ float: "right" }}
            >
              SEND ORDER
            </Button>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              color="primary"
              sx={{ textAlign: "center" }}
            >
              ORDER EMAIL
            </Typography>
          </Grid>
        </Grid>
        <Card elevation={8} sx={{ p: 2, mt: 2 }}>
          <CardContent>
            <TextField
              name="to"
              value={orderEmail.to}
              onChange={handleChange}
              label="To"
              sx={{ width: "100%" }}
            />
            <TextField
              name="cc"
              value={orderEmail.cc}
              onChange={handleChange}
              label="CC"
              sx={{ width: "100%", mt: 1 }}
            />
            <TextField
              name="bcc"
              value={orderEmail.bcc}
              onChange={handleChange}
              label="BCC"
              sx={{ width: "100%", mt: 1 }}
            />
          </CardContent>
        </Card>
        <Card elevation={8} sx={{ p: 2, mt: 2 }}>
          <CardContent>
            <TextField
              name="subject"
              value={orderEmail.subject}
              onChange={handleChange}
              label="Subject"
              sx={{ width: "100%", mt: 1 }}
            />
          </CardContent>
        </Card>
        <Card elevation={8} sx={{ p: 2, mt: 2 }}>
          <CardContent>
            <TextField
              name="text"
              value={orderEmail.text}
              onChange={handleChange}
              label="Email Body"
              multiline
              rows="35"
              sx={{ width: "100%", mt: 1 }}
            />
          </CardContent>
        </Card>
      </Paper>
    </Container>
  );
};

export default SendOrder;
