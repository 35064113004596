const SpectrumOrderBody = (data) => {
  const text = `
      Team,<br>
    
      We would like to proceed with ordering 200mb x 10mb standalone 1 year term. No static IP are required. <br> 
  
      <b>Primary Contact Name:</b> ${data.firstName} ${data.lastName} </n>
      <b>Primary Contact Phone:</b> ${data.phone} </n>
      <b>Secondary Contact phone:</b> 240-654-1884 </n>
      <b>Primary Contact Email:</b> optimaInternet@abttelecom.com </n>
      <b> Tech Contact name:</b> Glenn Taggart, 240-654-1884 gtaggart@abttelecom.com </n>
      <b>Billing Address:</b> OPTIMA Health Care, ATTN Kimberly Harriman, 8102 Tichenor Point Court, Millersville, MD 21108 - khharrim@sentara.com 757.983.2490</n>
      <b>Full Name of Business/Account:</b> Optima Health Care</n>
      <b>Fed ID #</b> 54-1283337
      <b>Full Address of Business:</b> ${data.address} ${data.city}, ${data.state} ${data.zip}</n>
      <b>Services Being Requested:</b> 120/20 Cable, Router/Modem with WiFi, No Static IP<br>
  
      <b>Authorized parties are:</b>  Glenn Taggart, Kimberly Harriman, Melissa Pitts,  ${data.firstName} ${data.lastName}<br>
      
      This is a residential location and we are installing a connection dedicated for work purposes.  The user has a residential service at this location as well.  I assume we will need special permission to install this business service in a residential location. <br>
      
      <p>Glenn A. Taggart</n>
      AB&amp;T Telecom</n>
      240-654-1884 Office</n>
      240-337-8367 Fax</n>
      gtaggart@abttelecom.com</n>
      http://www.abttelecom.com</n>
      
      <p>Galileo - Hosted Voice &amp; UC Service http://www.galileo-uc.com</p>
        `;

  return text;
};

export default SpectrumOrderBody;
