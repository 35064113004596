import React, { useContext, useEffect } from "react";
import navigationContext from "../../context/navigation/navigationContext";
import dataContext from "../../context/data/dataContext";
import PaymentsList from "./PaymentsList";

const Payments = () => {
  const { content } = useContext(navigationContext);
  const { fetchData, connections } = useContext(dataContext);

  switch (content) {
    default:
    case "Payments List":
      return <PaymentsList />;
  }
};

export default Payments;
