import CssBaseline from "@mui/material/CssBaseline";
import { Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import setAuthToken from "./utils/setAuthToken";
import AuthState from "./context/auth/AuthState";
import DataState from "./context/data/DataState";
import AlertState from "./context/alert/AlertState";
import NavigationState from "./context/navigation/NavigationState";
import PrivateRoute from "./routes/PrivateRoute";
import Login from "./pages/login/Login";
import RequestPasswordReset from "./pages/login/RequestPasswordReset";
import ResetPassword from "./pages/login/ResetPassword";
import NewUserPassword from "./pages/login/NewUserPassword";
import Header from "./components/nav/Header";
import OnlineOrderForm from "./pages/publicNewConnection/OnlineOrderForm";

const mdTheme = createTheme({
  palette: {
    primary: {
      main: "#3f3b3b",
    },
    secondary: {
      main: "#feb062",
    },
  },
});

if (localStorage.auth_token) {
  setAuthToken(localStorage.auth_token);
}

function App() {
  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <div className="App">
        <NavigationState>
          <AlertState>
            <AuthState>
              <DataState>
                <Routes>
                  <Route path="/" element={<PrivateRoute />}></Route>
                  <Route path="/login" element={<Login />}></Route>
                  <Route
                    path="/requestService"
                    element={<OnlineOrderForm />}
                  ></Route>
                  <Route
                    path="/passwordReset"
                    element={
                      <>
                        <Header />
                        <RequestPasswordReset />
                      </>
                    }
                  />
                  <Route
                    path="/resetPassword/:token"
                    element={
                      <>
                        <Header />
                        <ResetPassword />
                      </>
                    }
                  />
                  <Route
                    path="/newUserPassword/:token"
                    element={
                      <>
                        <Header />
                        <NewUserPassword />
                      </>
                    }
                  />
                </Routes>
              </DataState>
            </AuthState>
          </AlertState>
        </NavigationState>
      </div>
    </ThemeProvider>
  );
}

export default App;
