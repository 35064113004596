import passwordValidator from "password-validator";

const PasswordValidator = (password) => {
  const schema = new passwordValidator();

  schema
    .is()
    .min(8)
    .is()
    .max(100)
    .has()
    .uppercase()
    .has()
    .lowercase()
    .has()
    .digits()
    .has()
    .not()
    .spaces()
    .is()
    .not()
    .oneOf(["Passw0rd", "Password123"]);

  const checkPassword = schema.validate(password);
  return checkPassword;
};

export default PasswordValidator;
