import React, { useContext, useState, useEffect } from "react";
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TablePagination,
  Grid,
  TextField,
} from "@mui/material";
import DataContext from "../../context/data/dataContext";
import AuthContext from "../../context/auth/authContext";
import navigationContext from "../../context/navigation/navigationContext";
import mmddyy from "../../utils/mmddyy";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import ExportDataExcel from "../../utils/ExportDataExcel";

const ConnectionTable = ({
  setSearchBar,
  searchBar,
  setDisplayConnections,
  displayConnections,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  setFocusedConnection,
  module,
}) => {
  const { user } = useContext(AuthContext);
  const { connections, fetching, deleteData } = useContext(DataContext);
  const { loadModule } = useContext(navigationContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [searchBar, connections]);

  const search = () => {
    if (connections !== null && connections !== undefined && !fetching) {
      const searchResults = connections.filter((connection) => {
        const regex = new RegExp(`${searchBar}`, "gi");

        return (
          connection.firstName.match(regex) ||
          connection.lastName.match(regex) ||
          connection.address.match(regex) ||
          connection.city.match(regex) ||
          connection.zip.match(regex) ||
          connection.account_num.match(regex) ||
          connection.carrier.match(regex) ||
          connection.phone.match(regex) ||
          connection.email.match(regex)
        );
      });
      setDisplayConnections(searchResults);
    }
  };

  const handleSearch = (e) => {
    setSearchBar(e.target.value);
  };

  const viewConnection = (connection) => {
    setFocusedConnection(connection);
    loadModule({ module: module, content: "Edit" });
  };

  const fileName = module + " connections export";

  const deleteConnection = (id) => {
    deleteData(id);
  };

  return (
    <Paper sx={{ px: 4, py: 2 }}>
      <Grid container>
        <Grid item xs={12} lg={6}>
          <TextField
            size="small"
            type="search"
            onChange={handleSearch}
            name="searchBar"
            value={searchBar}
          ></TextField>
        </Grid>

        <Grid item xs={12} lg={6}>
          <ExportDataExcel csvData={displayConnections} fileName={fileName} />
        </Grid>
      </Grid>
      <TableContainer sx={{ maxHeight: 700, mt: 2 }}>
        <Table size="small" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  color: "#feb062",
                  backgroundColor: "#3f3b3b",
                  cursor: "pointer",
                }}
                onClick={() => handleRequestSort("carrier")}
              >
                Carrier
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "center",
                  color: "#feb062",
                  backgroundColor: "#3f3b3b",
                  cursor: "pointer",
                }}
                onClick={() => handleRequestSort("account_num")}
              >
                Account #
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "center",
                  color: "#feb062",
                  backgroundColor: "#3f3b3b",
                  cursor: "pointer",
                }}
                onClick={() => handleRequestSort("lastName")}
              >
                Last
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "center",
                  color: "#feb062",
                  backgroundColor: "#3f3b3b",
                  cursor: "pointer",
                }}
                onClick={() => handleRequestSort("firstName")}
              >
                First
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "center",
                  color: "#feb062",
                  backgroundColor: "#3f3b3b",
                  cursor: "pointer",
                }}
                onClick={() => handleRequestSort("address")}
              >
                Address
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "center",
                  color: "#feb062",
                  backgroundColor: "#3f3b3b",
                  cursor: "pointer",
                }}
                onClick={() => handleRequestSort("city")}
              >
                City
              </TableCell>
              {/* <TableCell
                sx={{
                  textAlign: 'center',
                  color: '#feb062',
                  backgroundColor: '#3f3b3b',
                  cursor: 'pointer',
                }}
                onClick={() => handleRequestSort('state')}
              >
                State
              </TableCell> */}
              {/* <TableCell
                sx={{
                  textAlign: 'center',
                  color: '#feb062',
                  backgroundColor: '#3f3b3b',
                  cursor: 'pointer',
                }}
                onClick={() => handleRequestSort('zip')}
              >
                Zip
              </TableCell> */}
              <TableCell
                sx={{
                  textAlign: "center",
                  color: "#feb062",
                  backgroundColor: "#3f3b3b",
                  cursor: "pointer",
                }}
                onClick={() => handleRequestSort("phone")}
              >
                Phone
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "center",
                  color: "#feb062",
                  backgroundColor: "#3f3b3b",
                  cursor: "pointer",
                }}
                onClick={() => handleRequestSort("email")}
              >
                Email
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "center",
                  color: "#feb062",
                  backgroundColor: "#3f3b3b",
                  cursor: "pointer",
                }}
                onClick={() => handleRequestSort("activeStatus")}
              >
                Status
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "center",
                  color: "#feb062",
                  backgroundColor: "#3f3b3b",
                  cursor: "pointer",
                }}
                onClick={() => handleRequestSort("orderPlacedDate")}
              >
                Placed
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "center",
                  color: "#feb062",
                  backgroundColor: "#3f3b3b",
                  cursor: "pointer",
                }}
                onClick={() => handleRequestSort("installDate")}
              >
                Install
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "center",
                  color: "#feb062",
                  backgroundColor: "#3f3b3b",
                  cursor: "pointer",
                }}
                onClick={() => handleRequestSort("disconnectionDate")}
              >
                Disconnect
              </TableCell>
              {user.role === "admin" ? (
                <TableCell
                  sx={{
                    textAlign: "center",
                    color: "#feb062",
                    backgroundColor: "#3f3b3b",
                  }}
                ></TableCell>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayConnections !== null && displayConnections !== undefined
              ? displayConnections
                  .sort(getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((connection) => (
                    <TableRow hover key={connection._id}>
                      <TableCell
                        onClick={() => viewConnection(connection)}
                        sx={{
                          textAlign: "left",
                          cursor: "pointer",
                        }}
                      >
                        {connection.carrier}
                      </TableCell>
                      <TableCell
                        onClick={() => viewConnection(connection)}
                        sx={{
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        {connection.account_num}
                      </TableCell>
                      <TableCell
                        onClick={() => viewConnection(connection)}
                        sx={{
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        {connection.lastName}
                      </TableCell>
                      <TableCell
                        onClick={() => viewConnection(connection)}
                        sx={{
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        {connection.firstName}
                      </TableCell>
                      <TableCell
                        onClick={() => viewConnection(connection)}
                        sx={{
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        {connection.address}
                      </TableCell>
                      <TableCell
                        onClick={() => viewConnection(connection)}
                        sx={{
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        {connection.city}
                      </TableCell>
                      {/* <TableCell>{connection.state}</TableCell> */}
                      {/* <TableCell>{connection.zip}</TableCell> */}
                      <TableCell
                        onClick={() => viewConnection(connection)}
                        sx={{
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        {connection.phone}
                      </TableCell>
                      <TableCell
                        onClick={() => viewConnection(connection)}
                        sx={{
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        {connection.email}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        {connection.activeStatus}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                        {connection.orderPlacedDate !== null
                          ? mmddyy(connection.orderPlacedDate)
                          : null}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                        {connection.installDate !== null
                          ? mmddyy(connection.installDate)
                          : null}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                        {connection.disconnectionDate !== null
                          ? mmddyy(connection.disconnectionDate)
                          : null}
                      </TableCell>
                      {user.role === "admin" ? (
                        <TableCell
                          sx={{
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                        >
                          <RemoveCircleIcon
                            color="error"
                            onClick={() => deleteConnection(connection._id)}
                            sx={{ cursor: "pointer" }}
                          />
                        </TableCell>
                      ) : null}
                      {/* <TableCell>{connection.publicNotes}</TableCell> */}
                    </TableRow>
                  ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      {displayConnections !== null &&
      displayConnections !== undefined &&
      displayConnections.length > 12 ? (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={displayConnections.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            color: "#feb062",
            backgroundColor: "#3f3b3b",
          }}
        />
      ) : null}
    </Paper>
  );
};

export default ConnectionTable;
